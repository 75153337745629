import React, { useState } from "react";
import { Button, Container, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  CloudUpload,
  PhotoCamera,
  DriveFolderUpload,
  ContentCopy,
} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Loader from "../../../shared/components/Loader";
import { mediaUpload } from "../../../services/mediaService";
import { toastMessage } from "../../../shared/components/Toast";
function Index() {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const uploadMedia = (e) => {
    let file = e?.target.files[0];
    console.log("file", file);
    let formBody = new FormData();
    setLoading(true);
    formBody.append("image", file);
    mediaUpload(formBody)
      .then(({ data }) => {
        setLink(data?.url)
      })
      .catch((err) => {
        toastMessage(err?.response?.data?.message, "error");
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Container>
        <Button
          disabled={loading}
          variant="contained"
          aria-label="upload picture"
          component="label"
          style={{
            width: "100%",
            height: "200px",
            background: "rgb(248, 61, 75)",
          }}
        >
          <input onChange={uploadMedia} hidden accept="image/*" type="file" />
          {loading ? (
            <Loader colors={"white"} />
          ) : (
            <CloudUpload style={{ width: "200px", height: "150px" }} />
          )}
        </Button>
      </Container>
      {link ? (
        <Container style={{ marginTop: "20px" }}>
          <Alert severity="success" style={{ alignItems: "center" }}>
            <div className="alertSuccess">
              <span>{link}</span>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  toastMessage("Link is Successfully Copied!");
                }}
              >
                <ContentCopy />
              </Button>
            </div>
          </Alert>
        </Container>
      ) : null}
    </>
  );
}

export default Index;
