import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const listAllCoupon = (query) => {
  return HTTP_Request.get(BASE_URL + Endpoints.coupon.list + query);
};
const createCouponServ = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.coupon.create, obj);
};
const deleteCoupon = (offerids) => {
  return HTTP_Request.delete(
    BASE_URL + Endpoints.coupon.deleteCoupon + "/" + offerids
  );
};

const updateCouponService = (offerId,obj) => {
  return HTTP_Request.patch(BASE_URL + Endpoints.coupon.update+offerId,obj);

}

export { listAllCoupon, createCouponServ, deleteCoupon,updateCouponService };
