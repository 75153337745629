import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Loader from "./../components/Loader";
import { CrossImage } from "./../../assets/images/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {ContentCopy} from "@mui/icons-material";
import { changePasswordService } from "../../services/authService";
import { toastMessage } from "./../components/Toast";
// import InputMask from "react-input-mask";
import { registerUser, updateUser } from "../../services/userService";
import InputMask from "react-input-mask";
import { listAllShop } from "../../services/shopService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterUser({
  open,
  handleClose,
  allUsers,
  setUsers,
  selectedUser,
}) {
  const [loader, setLoader] = useState(false);
  const [shopList, setShopList] = useState([]);
  const [fName, setfName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [referal, setReferal] = useState("");
  const [selectShop, setSelectShop] = useState("");

  const handleSubmit = () => {
    if (email && password && phone.length > 11 && fName && selectShop) {
      let obj = {
        email: email,
        password: password,
        phoneNumber: phone.replace("+1", ""),
        fullName: fName,
        shopId: selectShop,
      };

      setLoader(true);
      registerUser(obj)
        .then(({ data }) => {
          let cloneAllUser = [...allUsers];
          cloneAllUser = [data, ...cloneAllUser];

          setUsers(cloneAllUser);
          resetState();
          handleClose();
          toastMessage("User Register Successfully", "success");
        })
        .catch((err) => {})
        .finally(() => setLoader(false));
    } else {
      let msg = `${
        !selectShop
          ? "Shop"
          : !fName
          ? "full Name"
          : !email
          ? "email"
          : !(phone.length > 11)
          ? "Phone"
          : !password
          ? "password"
          : ""
      } is required`;
      toastMessage(msg, "error");
    }
  };
  const handleUpdate = () => {
    let obj = {
      email: email,
      phoneNumber: phone.replace("+1", ""),
      fullName: fName,
      shopId: selectShop,
    };
    console.log(obj);

    setLoader(true);
    updateUser(selectedUser?._id, obj)
      .then(({ data }) => {
        let cloneAllUser = [...allUsers];
        let findUserIndx = cloneAllUser.findIndex(
          (ii) => ii?._id == selectedUser?._id
        );
        cloneAllUser[findUserIndx] = data;

        setUsers(cloneAllUser);
        resetState();
        handleClose();
        toastMessage("User Update Successfully", "success");
      })
      .catch((err) => {})
      .finally(() => setLoader(false));
  };

  const onClose = () => {
    setPassword("");
    handleClose();
  };
  const resetState = () => {
    setfName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setReferal("");
    setSelectShop("");
  };
  const getALlShops = () => {
    listAllShop()
      .then(({ data }) => {
        setShopList(data);
      })
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    getALlShops();
  }, []);
  useEffect(() => {
    setfName(selectedUser?.fullName ? selectedUser?.fullName : "");
    setEmail(selectedUser?.email ? selectedUser?.email : "");
    setPhone(selectedUser?.phoneNumber ? selectedUser?.phoneNumber : "");
    setReferal(selectedUser?.referralCode ? selectedUser?.referralCode : "");
    setSelectShop(selectedUser?.shopId ? selectedUser?.shopId : "");
  }, [selectedUser]);
  const handleChange = (value) => {
    console.log("value", value);
    let dum = value.replace("-", "");
    setPhone(dum.replace("-", ""));
  };
  const checkSelectuser = Object.keys(selectedUser).length ? true : false;
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <Container
          sx={{
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <DialogTitle
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
            }}
            className="heading"
            color="text.secondary"
          >
            {checkSelectuser ? "Update" : "Register"} User
          </DialogTitle>
          <img
            src={CrossImage}
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
          <DialogContent>
            <TextFieldTitle text={"Select Shop:"} />
            <SelectInputComp
              list={shopList}
              selectShop={selectShop}
              setSelectShop={setSelectShop}
            />
            <TextFieldTitle text={"Full Name:"} />

            <TextFieldInput bool={true} setState={setfName} state={fName} />
            <TextFieldTitle text={"Email:"} />

            <TextFieldInput bool={true} setState={setEmail} state={email} />
            <TextFieldTitle text={"Enter Phone No."} />
            <TextInputMask
              value={phone}
              change={(e) => handleChange(e.target.value)}
            />
            {!Object.keys(selectedUser).length ? (
              <>
                <TextFieldTitle text={"Password:"} />
                <TextFieldInput
                  bool={true}
                  setState={setPassword}
                  state={password}
                  type={"password"}
                />
              </>
            ) : (
              ""
            )}

            <TextFieldTitle text={"Referral Code:"} />
            <TextFieldInput bool={true} setState={setReferal} disable={true} state={referal}
            endAdornment={ <Button
              onClick={() => {
                navigator.clipboard.writeText(referal);
                toastMessage("Refferal is Successfully Copied!");
              }}
            >
              <ContentCopy />
            </Button>}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (checkSelectuser) {
                  handleUpdate();
                } else {
                  handleSubmit();
                }
              }}
              sx={{
                backgroundColor: "#6C5DD3",
                boxShadow: "none",

                height: "56px",
                "&:hover": {
                  backgroundColor: "#6C5DD3",
                },
              }}
            >
              {loader ? <Loader /> : checkSelectuser ? "Update" : "Register"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}

const TextFieldInput = ({ bool, type, setState, state,disable,endAdornment }) => {
  const [showPassword, setShowPassword] = useState(bool);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //   const handleKeyDown = (event) => {
  //     if (event.key === "Enter") {
  //       handleSubmit();
  //     }
  //   };
  return (
    <>
      <TextField
        disabled={disable}
        InputProps={{
          style: {
            borderRadius: "12px",
            border: "none",
            backgroundColor: "#F8F8F9",
            height: "56px",
          },
          endAdornment: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                height: "100%",
              }}
              onClick={togglePasswordVisibility}
            >
             {endAdornment ? endAdornment :  ""}
            </Box>
          ),
        }}
        sx={{ width: "100%" }}
        type={type}
        value={state}
        onChange={(e) => setState(e.target.value)}
        // onKeyDown={handleKeyDown}
      />
    </>
  );
};

const TextFieldTitle = ({ text }) => {
  return (
    <>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#11142D",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        {text}
      </Typography>
    </>
  );
};

const TextInputMask = ({ value, change }) => {
  return (
    <>
      <InputMask
        style={{
          borderRadius: "12px",
          border: "none",
          backgroundColor: "#F8F8F9",
          height: "56px",
          width: "100%",
          padding: "0 18px",
        }}
        value={value}
        onChange={change}
        mask="+1999-999-9999"
        placeholder="+1999-999-9999"
      />
    </>
  );
};
const SelectInputComp = ({ list, selectShop, setSelectShop }) => {
  const handleChange = (event) => {
    setSelectShop(event.target.value);
  };
  return (
    <Select
      value={selectShop}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      style={{
        borderRadius: "12px",
        border: "none",
        backgroundColor: "#F8F8F9",
        height: "56px",
        width: "100%",
      }}
    >
      <MenuItem value="">
        <em>Select Shop</em>
      </MenuItem>
      {list?.map((item, inx) => {
        return (
          <MenuItem key={inx} value={item?._id}>
            {item?.nickname}
          </MenuItem>
        );
      })}
    </Select>
  );
};
