import React from 'react'

function Title({ title, titleStyle, role }) {
    return (
      <>
        <span className={titleStyle} role={role}>
          {title}
        </span>
      </>
    );
  }

export default Title
