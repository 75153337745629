import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { Container } from '@mui/system'
import { CrossImage, Sample } from '../../assets/images'
import { Typography } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function UserDetailView({
  open,
  handleClose,
  setDetail,
  detail,
}) {
  const onClose = () => {
    setDetail({})
    handleClose()
  }
  console.log('detail',detail);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <Container
          sx={{
            paddingTop: '40px',
            paddingBottom: '40px',
          }}
        >
          <img
            src={CrossImage}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
            onClick={() => handleClose()}
          />
          <DialogTitle
            sx={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 32,
              lineHeight: '48px',
              letterSpacing: '-0.5px',
              color: '#11142D',
              display: 'flex',
              justifyContent: 'center',
            }}
            className="heading"
            color="text.secondary"
          >
            View Details
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={
                detail?.imageURL ? detail?.imageURL 
                                   : Sample
                }
                height="200"
                width="200"
                style={{ borderRadius: '50%' }}
              />
            </div>
            <div style={{ marginTop: '3%', marginBottom: '5%' }}>
              <div className="flexCard userTitle" >
                {detail?.fullName}
              </div>
              <div className="flexCard userName">
                {detail?.phoneNumber}
              </div>
              
            </div>
            <Container>
                <DetailSection
                  heading="Email"
                  details={
                   detail?.email ? detail?.email:"--"
                  }
                />
              <DetailSection
                heading="Reffered By"
                details={
                 "--"
                }
              />
              <DetailSection
                heading="Owned Vehicles"
                details={
                 "--"
                }
              />
              <DetailSection
                heading="Current Reward"
                details={
                 detail?.rewardAmount
                }
              />
            </Container>
            {/* <h6>Refferals</h6>
            <div className="flexCard userName">
                {detail?.phoneNumber}
              </div> */}
           
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  )
}

const DetailSection = ({ heading, details }) => {
  return (
    <div className="flexContainer detailContainer flexModal">
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '19px',
        }}
      >
        {heading}
      </Typography>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '24px',
          textAlign: 'right',
          color: '#808191',
        }}
      >
        {details}
      </Typography>
    </div>
  )
}
