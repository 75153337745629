import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { getStats } from "../services/userService";
import { Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import GroupIcon from "@mui/icons-material/Group";
import Loader from "./../shared/components/Loader";
import PieChart from "../shared/components/PieChart";
import BarChart from "./../shared/components/Barchart";
import { useLogoutHelper } from "../utils/helper";

export default function BasicGrid({ open }) {
  const [allUsers, setAllUsers] = useState(0);
  const [adminAmount, setAdminAmount] = useState(0);
  const [blockedUsers, setBlockedUsers] = useState(0);
  const [streamerEarning, setStreamerEarning] = useState(0);
  const [totalMonthlyDonation, setTotalMonthlyDonation] = useState(0);
  const [totalMonthlySub, setTotalMonthlySub] = useState(0);
  const [unBlockedUsers, setUnBlockedUsers] = useState(0);
  const [loader, setLoader] = useState(false);
  const [blckSub, setBlckSub] = useState(0);
  const [ordComp, setOrdComp] = useState(0);
  const [ordInComp, setInComp] = useState(0);
  const { handleLogout } = useLogoutHelper();

  const getData = () => {
    setLoader(true);
    getStats()
      .then(({ data } = data) => {
        console.log("data", data);
        setAllUsers(data?.usersCount);
        setBlockedUsers(data?.blockedUsersCount);
        setUnBlockedUsers(data?.activeUsersCount);
        setTotalMonthlySub(data?.vehicleRegisteredCount);
        setAdminAmount(data?.appointmentsCount);
        setBlckSub(data?.vehicleDeletionRequestCount);
        setOrdComp(data?.repairOrderCompletedCount);
        setInComp(data?.repairOrderIncompletedCount);
        // setAllUsers(data?.)
        // setStreamerEarning(data?.data?.streamer_earning)
        // setAdminAmount(data?.data?.admin_cut)
        // setBlockedUsers(data?.data?.blocked_user_count)
        // setUnBlockedUsers(data?.data?.unblocked_user_count)
        // setTotalMonthlyDonation(data?.data?.donation_this_month)
        // setTotalMonthlySub(data?.data?.subscription_this_month)
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          handleLogout();
        }
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    getData();
  }, [open]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="gridBlocks"
        >
          <Grid item xs={4}>
            <div className="gridStyle" style={{ background: " #ada1ff" }}>
              <div
                className="disc"
                style={{
                  backgroundImage:
                    "linear-gradient(135deg, rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%)",
                  color: "#1f146e",
                }}
              >
                <GroupIcon />
              </div>

              <ItemDetail
                title={"Total Users :"}
                color={"rgb(6, 27, 100)"}
                value={allUsers}
              />
              <ItemDetail
                title={"Active Users :"}
                color={"rgb(6, 27, 100)"}
                value={unBlockedUsers}
              />
              <ItemDetail
                title={"Block Users :"}
                color={"rgb(6, 27, 100)"}
                value={blockedUsers}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="gridStyle"
              style={{ background: "rgb(255, 247, 205)" }}
            >
              <div
                className="disc"
                style={{
                  backgroundImage:
                    "linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%)",
                  color: "rgb(183, 129, 3)",
                }}
              >
                <CreditCardIcon />
              </div>

              <ItemDetail title={"Order Completed :"} value={ordComp} />
              <ItemDetail
                title={"Order Incompleted :"}
                value={ordInComp}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="gridStyle"
              style={{ background: "rgb(255, 231, 217)" }}
            >
              <div
                className="disc"
                style={{
                  backgroundImage:
                    "linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 100%)",
                  color: "rgb(183, 33, 54)",
                }}
              >
                <MonetizationOnIcon />
              </div>
              <ItemDetail color={"rgb(122, 12, 46)"} title={"Vehicle Register :"} value={totalMonthlySub} />
              <ItemDetail color={"rgb(122, 12, 46)"} title={"Vehicle Delete :"} value={blckSub} />
              <ItemDetail color={"rgb(122, 12, 46)"} title={"Vehicle Appointments :"} value={adminAmount} />
            </div>
          </Grid>
          {/* <Grid item xs={4}>
            <div
              className="gridStyle"
              style={{ background: 'rgb(208, 242, 255)' }}
            >
              <div
                className="disc"
                style={{
                  backgroundImage:
                    'linear-gradient(135deg, rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%)',
                  color: '#1f146e',
                }}
              >
                <VolunteerActivismIcon />
              </div>

              <Typography
                sx={{
                  fontSize: '1.875rem',
                  margin: '0px',
                  fontWeight: 700,
                  lineHeight: 1.5,
                  textAlign: 'center',
                  color: 'rgb(4, 41, 122)',
                }}
              >
                {'$' + totalMonthlyDonation}{' '}
              </Typography>
              <Typography
                sx={{
                  margin: '0px',
                  fontWeight: 600,
                  lineHeight: 1.57143,
                  fontSize: '0.875rem',
                  opacity: 0.72,
                  textAlign: 'center',
                  color: 'rgb(4, 41, 122)',
                }}
              >
                Total Donations earning
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="gridStyle"
              style={{ background: 'rgb(255 173 149)' }}
            >
              <div
                className="disc"
                style={{
                  backgroundImage:
                    'linear-gradient(135deg, rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%)',
                  color: 'rgb(122, 12, 46)',
                }}
              >
                <AddCardIcon />
              </div>

              <Typography
                sx={{
                  fontSize: '1.875rem',
                  margin: '0px',
                  fontWeight: 700,
                  lineHeight: 1.5,
                  textAlign: 'center',
                  color: 'rgb(122, 12, 46)',
                }}
              >
                {'$' + streamerEarning}{' '}
              </Typography>
              <Typography
                sx={{
                  margin: '0px',
                  fontWeight: 600,
                  lineHeight: 1.57143,
                  fontSize: '0.875rem',
                  opacity: 0.72,
                  textAlign: 'center',
                  color: 'rgb(122, 12, 46)',
                }}
              >
                Total streamer earning
              </Typography>
            </div>
          </Grid> */}
        </Grid>
      </Box>
      {loader ? (
        <div className="flexCard" style={{ marginTop: "15%" }}>
          <Loader />
        </div>
      ) : (
        <>
          {/* <RadialChart
            allUsers={allUsers}
            blockedUsers={blockedUsers}
            unBlockedUsers={unBlockedUsers}
          />
          <DonutChart
            adminAmount={adminAmount}
            streamerEarning={streamerEarning}
            totalMonthlyDonation={totalMonthlyDonation}
            totalMonthlySub={totalMonthlySub.toFixed(3)}
          /> */}

          <PieChart
            totalUser={allUsers}
            activeUser={unBlockedUsers}
            blockUser={blockedUsers}
            vehicleRegister={totalMonthlySub}
          />
          {/* <BarChart
            allUsers={allUsers}
            blockedUsers={blockedUsers}
            unBlockedUsers={unBlockedUsers}
          /> */}
        </>
      )}
    </>
  );
}
const ItemDetail = ({ title, value, color }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
      >
        <Typography
          sx={{
            margin: "0px",
            fontWeight: 600,
            lineHeight: 1.57143,
            fontSize: "0.875rem",
            opacity: 0.72,
            textAlign: "center",
            color: color ? color : "rgb(122, 79, 1)",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.875rem",
            margin: "0px",
            fontWeight: 700,
            lineHeight: 1.5,
            textAlign: "center",
            color: color ? color : "rgb(122, 79, 1)",
          }}
        >
          {value}
        </Typography>
      </div>
    </>
  );
};
