import { useRef, useState } from "react";
import Title from "../title";
import { isNumberCheck } from "../../../utils/helper";
import styles from "./style.module.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function Pagination(props) {
  const { page, setPage, lastPage, listSize, seChngetPage } = props;
  const pageRef = useRef(page);
  const [inputText,setInputText] = useState(page)
  const [isNumber, setIsNumber] = useState(false);

  function myKeyPress(e) {
    if (e.code === "Backspace") {
      setIsNumber(true);
    } else {
      setIsNumber(isNumberCheck(e));
    }
  }

  const pageInputHandler = (text) => {
    if (Number(text) > 0) {
      if (isNumber) {
        if (Number(text) < lastPage + 1) {
          setPage?.(text);
          pageRef.current = text;
          if (text) {
            seChngetPage?.(text);
          }
        } else {
          setPage?.(lastPage);
          pageRef.current = lastPage;
          seChngetPage?.(lastPage);
        }
      } else {
        setPage?.(1);
        pageRef.current = "1";
        seChngetPage?.(1);
      }
    } else if (text === "") {
      setPage?.(text);
      pageRef.current = text;
      if (text) {
        seChngetPage?.(text);
      }
    } else {
      setPage?.(1);
      pageRef.current = "1";
      seChngetPage?.(1);
    }
  };
 

  return (
    <>
      <div className={styles.paginationContainer}>
        <Title
          title={`Showing ${listSize} results for page ${page} of ${lastPage}`}
          titleStyle={styles.page_title}
        />
        <div className="d-flex">
          <div
            className="tablePaginate"
            onClick={() => {
              if (page !== 1) {
                setPage?.(page ? page - 1 : 1);
                seChngetPage?.(page ? page - 1 : 1);
              }
            }}
            
          >
            <KeyboardArrowLeftIcon />
          </div>
          <input
            type={"text"}
            value={page}
            onChange={(e) =>  pageInputHandler(e.target.value)}
            className={styles.page_input}
            onKeyDown={myKeyPress}
            onBlur={() => {
              if (pageRef.current === "") {
                setPage?.(1);
              }
            }}
          />
          <div
            className="paginateLeft"
            onClick={() => {
              if (page < lastPage) {
                setPage?.(page ? page + 1 : 1);
                seChngetPage?.(page ? page + 1 : 1);
              }
            }}
          >
            <KeyboardArrowRightIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default Pagination;
