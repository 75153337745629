import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";


export const DelRewardService = (query) => {
  // let obj = {
  //   page: 1,
  //   limit: 10,
  //   ,
  // };

  return HTTP_Request.post(
    BASE_URL + Endpoints.rewards.delReward+query
  );
};