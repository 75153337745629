import React from 'react'
import { ResponsivePie } from '@nivo/pie'

const MyResponsivePie = ({
  totalUser,
  activeUser,
  blockUser,
  vehicleRegister,
}) => {
  const data = [
    { id: 'Total Users', value: totalUser, color: '#f6c044' },
    { id: 'Active Users', value: activeUser, color: '#3b7ba9' },
    { id: 'Block Users', value: blockUser, color: '#d04a35' },
    { id: 'Register Vehicle', value: vehicleRegister, color: '#518a87' },
  ]

  return (
    <div style={{ height: '400px' }}>
      <div
        style={{
          fontSize: '1.875rem',
          margin: '0px',
          fontWeight: 700,
          lineHeight: 1.5,
          textAlign: 'center',
          color: 'rgb(6, 27, 100)',
          marginTop: '7%',
        }}
      >
        All User Register 
      </div>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ datum: 'data.color' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', '0.2']] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#333333"
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}

export default MyResponsivePie
