import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";



// const getUserService = () => {
//   return HTTP_Request.get(BASE_URL + Endpoints.getUser);
// }

const getUserService = (query) => {
  return HTTP_Request.get(BASE_URL + Endpoints.users.list+query);
}

const blockService = (userId) => {
  return HTTP_Request.post(BASE_URL + Endpoints.users.blockUser+`${userId}/block`);
}

const unblockService = (userId) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.users.unBlockUser+`${userId}/block`);
}
const registerUser = (obj)=>{
  return HTTP_Request.post(BASE_URL + Endpoints.users.adminRegisterUser,obj);
}
const updateUser = (userId,obj)=>{
  return HTTP_Request.patch(BASE_URL + Endpoints.users.adminUpdateUser+userId,obj);
}
const deleteUser = (userId)=>{
  return HTTP_Request.delete(BASE_URL + Endpoints.users.deleteUser+userId);
}


const getDonorService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.getDonors, formBody);
}

const getSubService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.getSubs, formBody);
}

const getStats = () => {
  let obj = {
    filters: {
      fromDate: "01-01-2023",
      toDate: "12-13-2033",
    },
  };
  return HTTP_Request.post(BASE_URL + Endpoints.dashboard.stats,obj);
};

export { getUserService, blockService, unblockService, getDonorService, getSubService, getStats,registerUser,updateUser,deleteUser };
