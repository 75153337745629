let Endpoints = {
  login: "admin/login",
  notification: "users/notifications",
  offer: {
    list: "offer",
    create: "offer",
    update: "offer/",
    deleteOffer: "offer",
  },
  blog: {
    list: "blog",
    create: "blog",
    deleteBlog: "blog",
  },
  setting: {
    dynamicWithComma: "dynamic-keys/",
    updateDynamicKey: "dynamic-keys/",
  },
  media: {
    upload: "media",
  },
  shop: {
    list: "shops",
    updateShop: "shops/",
  },
  users: {
    list: "admin/users",
    awardHistory: "admin/reward-transaction-history",
    blockUser: "users/",
    unBlockUser: "users/",
    adminRegisterUser: "admin/users",
    adminUpdateUser: "admin/users/",
    deleteUser: "users",
    rewardHist: "admin/reward-transaction-history/",
  },
  dashboard: {
    user: "admin/get-users-count",
    appointment: "admin/get-appointments-count",
    repairOrder: "admin/get-repair-orders-count",
    stats: "admin/get-stats",
  },
  appointment: {
    getAppintment: "admin/get-appointments",
    deleteAppointment: "appointment/",
    allReason: "appointment-reason",
    createAppointment: "admin/appointments",
    updateAppointment: "appointment/",
  },
  rewards: {
    addReward: "admin/add-reward/",
    delReward: "admin/remove-reward/",
  },
  coupon: {
    list: "coupon",
    create: "coupon",
    deleteCoupon: "coupon",
    update: "coupon/",
  },
  // logout: "/admin-logout",
  // changePassword: "/change-password-admin",
  // getUser: "/get-users",
  // blockUser: "/block-user",
  // unBlockUser: "/unblock-user",
  // getPrivacyPolicy: "/privacy-policy/1",
  // getTerms: "/privacy-policy/0",
  // updatePrivacyPolicy: "/update-privacy-policy/1",
  // updateTerms: "/update-privacy-policy/0",
  // getDonors: "/donors-list",
  // getSubs: "/subscription-list",
  // getDashboardData: "/dashboard-stats"
};

export {
  Endpoints
}