import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { Formik } from "formik";
import Loader from "../../../shared/components/Loader";
import { useNavigate } from "react-router-dom";
import {
  listDynamicURl,
  updateDynamicURl,
} from "../../../services/settingService";
import { dynamicKey } from "../../../utils/constant";
import { toastMessage } from "../../../shared/components/Toast";
import { logoVS } from "../../../shared/validation/validation";
function SystemLogo() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [loyalBonus, setLoyalBonus] = useState("");
  const [appLogo, setAppLogo] = useState("");
  useEffect(() => {
    let cloneState = {};
    let obj = {};
    let query = "InAppLogo";
    listDynamicURl(query)
      .then(({ data }) => {
        let selectedKey = [
          {
            key: "InAppLogo",
            statePropty: "systemLogo",
          },
        ];

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let findKey = selectedKey.find((ii) => ii.key === element?.key);
          if (findKey && Object.keys(findKey)?.length) {
            obj = {
              ...obj,
              [findKey.statePropty]: element.value,
            };
          }
        }
        obj = {
          ...cloneState,
          ...obj,
        };

        setAppLogo(obj?.systemLogo);
        // setReferalBonus(obj?.refereal_bonus_value)
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  const handleUpdate = (key, values) => {
    let obj = {
      value: values,
    };

    updateDynamicURl(key, obj)
      .then(({ data }) => {
        toastMessage("Dynamic key value is successfully updated", "success");
      })
      .catch((err) => {
        toastMessage(err?.response?.data?.message, "error");
      });
    console.log({ key, obj });
  };
  const handleSubmit = (key) => {
    if (dynamicKey.inAppLogo == key) {
      handleUpdate(key, appLogo);
    }
  };
  return (
    <Box className="">
      <Box
        borderBottom={"1px solid #B9C5D275"}
        flexDirection={"column"}
        display={"flex"}
      >
        <Typography
          variant="h5 "
          fontFamily={"inter"}
          fontSize={"20px"}
          fontWeight={600}
          sx={{ mb: 1, mt: 3, ml: 3, color: "#2F496C" }}
        >
          System Logo
        </Typography>

        <Typography
          variant="body6"
          fontFamily={"inter"}
          fontSize={"12px"}
          fontWeight={400}
          sx={{ mb: 2.5, ml: 3, color: "#899CAF" }}
        >
          one place for Editing Company Logo all over the app
        </Typography>
      </Box>
      <Box sx={{ px: 3 }}>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="body5"
                fontFamily={"inter"}
                fontSize={"12px"}
                fontWeight={500}
                color={"#899CAF"}
                sx={{ ml: 0.7 }}
                id="starick"
              >
                System Logo
              </Typography>
            </Box>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mt: 1.5 },
                "& .MuiInputBase-root": {
                  height: 50,
                },
                "& .MuiFormControl-root": {
                  width: "100%",
                },
                "& .MuiBox-root": {
                  display: "flex",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                name="offer_tital"
                sx={{
                  "& > :not(style)": {
                    width: "100%",
                    border: "1px solid",
                  },
                }}
                placeholder="System Logo"
                value={appLogo}
                onChange={(e) => setAppLogo(e.target.value)}
              />
              <Button
                style={{
                  background: "rgb(248, 61, 75)",
                  color: "white",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  "&:hover": {
                    backgroundColor: "rgb(248, 61, 75)",
                    color: "white",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }}
                onClick={() => handleSubmit(dynamicKey.inAppLogo)}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default SystemLogo;
