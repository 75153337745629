/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "../../styles/table.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { listAllOffer, deleteOffer } from "../../services/offerService";
import {
  appointmentService,
  deleteAppointment,
} from "../../services/appointmentService";
import Loader from "../../shared/components/Loader";
import { Sample } from "../../assets/images";
// import Pagination from "../../shared/components/Pagination";
import { NoData } from "../../assets/images/index";
import { toastMessage } from "../../shared/components/Toast";
import { useLogoutHelper, pageLmitConver } from "../../utils/helper";
import { ModeEdit, Delete, Visibility, Add } from "@mui/icons-material";
import ViewDetail from "../../shared/Modals/ViewDetails";
import ChangePassword from "../../shared/Modals/ChangePassword";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/debounce";
import moment from "moment/moment";
import Pagination from "../../shared/components/customPaginate/customPagination.jsx";
import NewCar from "../../assets/images/new_car.svg";
export default function Appointment() {
  let types = {
    single: "single",
    multiple: "multiple",
  };
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(true);
  const [allAppointment, setAllAppintment] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [changePage, seChngetPage] = useState(1);
  const [active, setActive] = useState(1);
  const [allId, setAllId] = useState([]);
  const [appointmentDetail, setAppointmentDetail] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [delLoader, setDelLoader] = useState(false);
  const [delIndx, setDelIndx] = useState(-1);
  const { handleLogout } = useLogoutHelper();

  const getDonor = () => {
    let obj = {
      page: changePage,
      limit: 10,

      filters: {
        fromDate: "01-01-2023",
        toDate: "12-30-2032",
      },
    };
    if (searchText) {
      obj["search"] = searchText;
    }

    // let query = `?page=${changePage}&limit=10${searchText ? "&search=" + searchText : ""} `;
    setLoader(true);
    appointmentService(obj)
      .then(({ data } = data) => {
        setLoader(false);
        if (data?.total > 10) {
          setLastPage(pageLmitConver(data?.total));
        } else {
          setLastPage(data?.total);
        }
        debugger;
        setAllAppintment(data?.appointments);
      })
      .catch((err) => {
        toastMessage(err?.message, "error");
        setLoader(false);
        if (err?.response?.status == 401) {
          handleLogout();
        }
      });
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const changCheckBox = (boxId) => {
    let cloneId = [...allId];
    let checkIndx = allId.findIndex((ii) => ii == boxId?._id);
    if (checkIndx > -1) {
      cloneId.splice(checkIndx, 1);
      setAllId(cloneId);
    } else {
      cloneId.push(boxId?._id);
      setAllId(cloneId);
    }
  };
  // useEffect(() => {
  //   getDonor();
  // }, []);
  const selectAll = () => {
    if (allId.length < 1) {
      let allids = allAppointment.map((ii) => ii?._id);
      setAllId(allids);
    } else {
      setAllId([]);
    }
  };
  const handleClickOpenDetail = (user) => {
    setOpenDetail(true);
    setAppointmentDetail(user);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };
  const handleDelete = (type, ids) => {
    let cloneAllAppointment = [...allAppointment];

    if (type == types.single) {
      setDelLoader(true);

      deleteAppointment(ids)
        .then(({ data: { message } }) => {
          let checkIndx = cloneAllAppointment.findIndex((ii) => ii?._id == ids);
          if (checkIndx > -1) {
            cloneAllAppointment.splice(checkIndx, 1);
            setAllAppintment(cloneAllAppointment);
          }
          toastMessage(message, "success");
          //remove select id from state
          if (allId?.length) {
            let cloneAllIds = [...allId];
            let checkIdIndx = cloneAllIds.findIndex((ii) => ii == ids);
            if (checkIdIndx > -1) {
              cloneAllIds.splice(checkIdIndx, 1);
              setAllId(cloneAllIds);
            }
          }
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    } else {
      setDelLoader(true);
      deleteAppointment(allId?.toString())
        .then(({ data: { message } }) => {
          for (let index = 0; index < allId.length; index++) {
            const element = allId[index];
            let checkIndx = cloneAllAppointment.findIndex(
              (ii) => ii?._id == element
            );
            if (checkIndx > -1) {
              cloneAllAppointment.splice(checkIndx, 1);
            }
          }
          setAllId([]);
          setAllAppintment(cloneAllAppointment);

          toastMessage(message, "success");
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    }
  };
  useDebounce(
    () => {
      setLoader(true);
      getDonor();
    },
    [searchText, changePage],
    800
  );

  return (
    <>
      <div className="flex spaceBetween itemCenter">
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "48px",
            letterSpacing: "-0.5px",
            color: "#11142D",
            marginBottom: "2%",
          }}
        >
          All Appointments
        </Typography>
        <div>
          {allId.length > 1 ? (
            <Button
              sx={{
                background: " rgba(255, 183, 190, 0.9);",
                color: "red",
                marginRight: "10px",
              }}
              onClick={() => handleDelete(types.multiple)}
            >
              <Delete style={{ color: "red" }} />
              Delete
            </Button>
          ) : (
            ""
          )}

          <Button variant="outlined" onClick={() => navigate("/createAppoint")}>
            <Add />
            Create Appointment
          </Button>
        </div>
      </div>

      <TextField
        id="outlined-basic"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: "12px",
            border: "none",
            backgroundColor: "#F8F8F9",
            height: "56px",
          },
          autoComplete: "off",
        }}
        sx={{ width: "100%" }}
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        autoComplete="off"
      />
      {loader ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {allAppointment?.length > 0 ? (
            <>
              <TableContainer
                className="tabledContainer"
                sx={{
                  marginTop: "2%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={allId.length ? true : false}
                          onChange={() => selectAll()}
                          {...label}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>Vehicle Details (Make,Model,Year)</TableCell>
                      <TableCell>Owned By</TableCell>
                      <TableCell>Milage (KMs)</TableCell>
                      <TableCell>Appointment Reason</TableCell>
                      <TableCell>Appointment Time</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allAppointment?.map((row, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              {...label}
                              checked={allId.includes(row?._id)}
                              size="small"
                              onChange={() => changCheckBox(row)}
                            />
                          </TableCell>
                          <TableCell>
                            <div className="tableNameDiv">
                              <img
                                src={
                                  row?.vehicle?.imageURL
                                    ? row?.vehicle?.imageURL
                                    : NewCar
                                }
                                height="50"
                                width={"100"}
                                // style={{ borderRadius: "50%" }}
                              />
                              {row?.vehicle?.imageURL ? (
                                <div style={{ marginLeft: "10%" }}>
                                  <div className="taleName">
                                    {row?.vehicle?.tekmetricRaw?.make}{" "}
                                    {row?.vehicle?.tekmetricRaw?.model}{" "}
                                    {row?.vehicle?.tekmetricRaw?.year}
                                  </div>
                                </div>
                              ) : (
                                <div style={{ marginLeft: "10%" }}>
                                  <div className="taleName">New Car</div>
                                </div>
                              )}
                            </div>
                          </TableCell>

                          <TableCell>
                            <div>
                              <span>open</span>
                              <a
                                style={{ marginLeft: "10px" }}
                                target={"_blank"}
                                href={row?.ctaValue}
                              >
                                {row?.ctaKind}
                              </a>
                            </div>
                          </TableCell>
                          <TableCell>
                            {row?.vehicle
                              ? row?.vehicle?.mileage / 1000 + "KM"
                              : "--"}
                          </TableCell>
                          <TableCell>{row?.description}</TableCell>
                          <TableCell>
                            {moment(row?.appointmentAt).format(
                              "DD MMM, YYYY h:mm a"
                            )}
                          </TableCell>
                          <TableCell>
                            <div style={{ display: "flex" }}>
                              <Button
                                variant="outlined"
                                sx={{
                                  minWidth: "45px",
                                  width: "35px",
                                  height: "40px",
                                  borderColor: "#c8bcbc !important",
                                  "&:hover": {
                                    background: "#FEE8CE",
                                  },
                                }}
                                onClick={() => handleClickOpenDetail(row)}
                              >
                                <Visibility style={{ color: "#FD8A25" }} />
                              </Button>
                              {/* <Button
                                variant="outlined"
                                sx={{
                                  marginLeft: "5%",
                                  marginRight: "5%",
                                  minWidth: "45px",
                                  width:"35px",
                                  height: "40px",
                                }}
                                onClick={() => {
                                  let cloneObj = {...row};
                                  if(cloneObj.user){

                                    cloneObj.user["vehicles"] = [row?.vehicle]
                                    delete cloneObj.vehicle
                                  }
                                  navigate("/createAppoint", {
                                    state: {
                                      appointment: cloneObj,
                                      isEdit: true,
                                    },
                                  });
                                  
                                }}
                              >
                                <ModeEdit />
                              </Button> */}
                              <Button
                                variant="outlined"
                                sx={{
                                  marginLeft: "5%",
                                  marginRight: "5%",
                                  minWidth: "45px",
                                  width: "35px",
                                  height: "40px",
                                  borderColor:
                                    "rgba(255, 183, 190, 0.9) !important",
                                  "&:hover": {
                                    background: " rgba(255, 183, 190, 0.9);",
                                  },
                                }}
                                onClick={() => {
                                  setDelIndx(index);
                                  handleDelete(types.single, row?._id);
                                }}
                              >
                                {delLoader && index == delIndx ? (
                                  <Loader colors={"red"} />
                                ) : (
                                  <Delete style={{ color: "red" }} />
                                )}
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                listSize={lastPage}
                page={active}
                setPage={setActive}
                seChngetPage={seChngetPage}
                lastPage={lastPage}
              />
              {/* <Pagination
                lastPage={lastPage}
                active={active}
                setActive={setActive}
              /> */}
            </>
          ) : (
            <div className="flexCard" style={{ marginTop: "10%" }}>
              <img src={NoData} />
            </div>
          )}
        </>
      )}
      <ViewDetail
        setDetail={setAppointmentDetail}
        detail={appointmentDetail}
        open={openDetail}
        handleClose={handleCloseDetail}
        appointMentPlaceHolder={
          "https://app.integrity1auto.com/static/media/new_car.c806dc8f50ba8655f8393085e13f112f.svg"
        }
      />
      {/* <ChangePassword /> */}
    </>
  );
}
