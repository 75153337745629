import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getUserService } from "../services/userService";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../styles/table.css";
import { timeConstant, earlyRiser, earlyEnum } from "../utils/constant";
import React from "react";
import CustomInput from "../shared/components/AutoComplete/CustomServerInput";
import SuggestionAutoComplete from "../shared/components/AutoComplete/autoComplete";
import { RewardService } from "../services/rewardService";
import useDebounce from "../hooks/debounce";
import {
  getReasonAppoinment,
  createAppointment,
  updateAppointment,
} from "../services/appointmentService";
import moment from "moment/moment";
import { toastMessage } from "../shared/components/Toast";

const Creatappointment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appointmentState = location?.state?.appointment;
  const [allUse, setAllUse] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userOpenSuggestion, setUserOpenSuggestion] = useState(false);
  const [vehicleSuggestion, setVehicleSuggestion] = useState(false);
  const [reasonSuggestn, setReasonSuggestn] = useState(false);
  const [search, setSearch] = useState(
    appointmentState?.user?.fullName ? appointmentState?.user?.fullName : ""
  );
  const [vehicleSearch, setVehicleSearch] = useState(
    appointmentState?.user?.vehicles[0]?.name
      ? appointmentState?.user?.vehicles[0]?.name
      : ""
  );
  const [reasonSearch, setReasonSearch] = useState(
    appointmentState?.appointmentReason?.title
      ? appointmentState?.appointmentReason?.title
      : ""
  );
  const [description, setDescription] = React.useState(
    appointmentState?.description ? appointmentState?.description : ""
  );
  const [value, onChange] = useState(
    new Date(
      appointmentState?.appointmentAt
        ? appointmentState?.appointmentAt
        : Date.now()
    )
  );
  const [selectUser, setSelectUser] = useState(
    appointmentState?.user ? appointmentState?.user : null
  );
  const [selectVehicle, setSelectVehcile] = useState(
    appointmentState?.user?.vehicles[0]
      ? appointmentState?.user?.vehicles[0]
      : null
  );
  const [selectReason, setReason] = useState(
    appointmentState?.appointmentReason
      ? appointmentState?.appointmentReason
      : null
  );
  const [selectTime, setSelectTime] = useState(null);
  const [allReason, setAllReason] = useState([]);
  const [selectEarly, setEarly] = useState(null);
  const handleEarly = (earlyItem) => {
    if (earlyItem?.text != selectEarly?.text) {
      setEarly(earlyItem);
    } else {
      setEarly(null);
    }
  };
  useEffect(() => {
    getAllUser();
    getAllReason();
  }, []);
  const getAllUser = () => {
    setLoader(true);
    let query = `?search=${search}`;
    getUserService(query)
      .then(({ data: data }) => {
        setAllUse(data?.users);
        setAllVehicles(data?.users);
        // console.log(data,'<==datauser')
      })
      .finally(() => setLoader(false));
  };
  useDebounce(
    () => {
      getAllUser();
    },
    [search],
    800
  );
  const getAllReason = () => {
    getReasonAppoinment().then(({ data }) => {
      setAllReason(data);
    });
  };
  const handleSelctTime = (time) => {
    let date = value;
    date.setHours(time.value);
    date.setMinutes(0);
    date.setSeconds(0);
    setSelectTime(time.time);
    onChange(date);
  };
  const handleSubmit = () => {
    let obj = {
      appointmentAt: moment(value).format("YYYY-MM-DDTHH:MM:SS"),
      isEarlyBird: false,
      isAfterHours: false,
      description: description,
    };
    if (selectUser?._id) {
      obj["userId"] = selectUser?._id;
    }
    if (selectReason?._id) {
      obj["appointmentReason"] = selectReason?._id;
    }
    if (selectVehicle?._id) {
      obj["vehicle"] = selectVehicle?._id;
    }
    if (selectEarly?.text) {
      if (earlyEnum.early == selectEarly?.text) {
        obj.isEarlyBird = true;
        obj.isAfterHours = false;
      } else {
        obj.isAfterHours = true;
        obj.isEarlyBird = false;
      }
    }
    if (selectReason?._id) {
      createAppointment(obj)
        .then(({ data }) => {
          navigate("/appointments");
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
        });
    } else {
      let query = `${
        selectUser?._id
          ? "User"
          : selectTime
          ? "Please Select the Time "
          : selectReason?._id
          ? "Appointment Reason"
          : ""
      } is required`;
      toastMessage(query, "error");
    }
  };
  const handleUpdate = () => {
    let obj = {
      appointmentAt: moment(value).format("YYYY-MM-DDTHH:MM:SS"),
      isEarlyBird: false,
      isAfterHours: false,
      description: description,
    };
    if (selectUser?._id) {
      obj["userId"] = selectUser?._id;
    }
    if (selectReason?._id) {
      obj["appointmentReason"] = selectReason?._id;
    }
    if (selectVehicle?._id) {
      obj["vehicle"] = selectVehicle?._id;
    }
    if (selectEarly?.text) {
      if (earlyEnum.early == selectEarly?.text) {
        obj.isEarlyBird = true;
        obj.isAfterHours = false;
      } else {
        obj.isAfterHours = true;
        obj.isEarlyBird = false;
      }
    }
    if (selectReason?._id) {
      updateAppointment(appointmentState?._id, obj)
        .then(({ data }) => {
          navigate("/appointments");
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
        });
    } else {
      let query = `${
        selectUser?._id
          ? "User"
          : selectTime
          ? "Please Select the Time "
          : selectReason?._id
          ? "Appointment Reason"
          : ""
      } is required`;
      toastMessage(query, "error");
    }
  };
  useEffect(() => {
    if (appointmentState?.appointmentAt) {
      let ConverToNumb = Number(
        moment(appointmentState?.appointmentAt).format("H")
      );
      if (ConverToNumb >= 18 && ConverToNumb <= 24) {
        ConverToNumb = Number(
          moment(appointmentState?.appointmentAt).format("h")
        );
      }
      setSelectTime(timeConstant.find((ii) => ii.value == ConverToNumb)?.time);
    }
    if (appointmentState?.isEarlyBird) {
      setEarly(earlyRiser[0]);
    } else if (appointmentState?.isAfterHours) {
      setEarly(earlyRiser[1]);
    }
  }, [appointmentState?.appointmentAt]);

  return (
    <>
      <Box>
        <Box height={70} />
        <Box display={"flex"}>
          <Box>
            <Typography
              variant="h5 "
              fontFamily={"inter"}
              fontSize={"20px"}
              fontWeight={600}
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  fontFamily: "inter",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#2F496C",
                }}
              >
                All Appointments
              </button>
            </Typography>
          </Box>
          <Box sx={{ ml: 1 }}>
            <Typography
              variant="h5 "
              fontFamily={"inter"}
              fontSize={"20px"}
              fontWeight={600}
            >
              <span>&gt;</span> Create Appointment
            </Typography>
          </Box>
        </Box>
        <Box className="husnain" sx={{ mt: 2, pb: 5 }}>
          <Box sx={{ borderBottom: "1px solid #B9C5D275", pb: 2 }}>
            <Box display={"flex"} flexDirection={"column"}>
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="body3"
                  fontFamily={"inter"}
                  fontSize={"20px"}
                  fontWeight={600}
                  sx={{ color: "#2F496C", ml: 2, mb: 1 }}
                >
                  Create Appointment
                </Typography>
              </Box>

              <Typography
                variant="body6"
                fontFamily={"inter"}
                fontSize={"12px"}
                fontWeight={500}
                sx={{ color: "#899CAF", ml: 2, mb: 1 }}
              >
                Fill form to create an appointment directly from admin panel
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              border: "1px solid #B9C5D275",
              borderRadius: "5px",

              mx: 1.5,
              pb: 3,
            }}
          >
            <Typography
              fontFamily={"inter"}
              fontSize={"12px"}
              fontWeight={500}
              sx={{ bgcolor: "#F8F8F8", py: 1.5, color: "#899CAF", pl: 1 }}
            >
              User Details
            </Typography>
            <Box>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <Typography
                    fontFamily={"inter"}
                    fontSize={"12px"}
                    fontWeight={500}
                    sx={{ mt: 2, mb: 2, ml: 2 }}
                    id="starick"
                  >
                    Select User
                  </Typography>
                  <Box sx={{ ml: 2 }}>
                    <CustomInput
                      userArr={allUse}
                      onSelect={(selectUser) => {
                        setUserOpenSuggestion(false);
                        setSelectUser(selectUser);
                        setSearch(selectUser?.fullName);
                      }}
                      loader={loader}
                      value={search}
                      change={(e) => {
                        setSelectUser(null);
                        setSelectVehcile(null);
                        setVehicleSearch("");
                        setSearch(e.target.value);
                      }}
                      setOpenSuggestion={setUserOpenSuggestion}
                      openSuggestion={userOpenSuggestion}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    fontFamily={"inter"}
                    fontSize={"12px"}
                    fontWeight={500}
                    sx={{ mt: 2, mb: 2, ml: 2 }}
                    id="starick"
                  >
                    Select Vehicle
                  </Typography>
                  <Box sx={{ ml: 2, mr: 2 }}>
                    <SuggestionAutoComplete
                      userArr={selectUser?.fullName ? selectUser?.vehicles : []}
                      onSelect={(selectVehicle) => {
                        setSelectVehcile(selectVehicle);
                        setVehicleSearch(selectVehicle?.name);
                        setVehicleSuggestion(false);
                      }}
                      disable={selectUser?.fullName ? false : true}
                      loader={false}
                      search={vehicleSearch}
                      searchByfieldName={"name"}
                      setSearch={setVehicleSearch}
                      setOpenSuggestion={setVehicleSuggestion}
                      vehicleSuggestion={vehicleSuggestion}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              border: "1px solid #B9C5D275",
              borderRadius: "5px",
              overflow: "hidden",
              mx: 1.5,
              pb: 3,
            }}
          >
            <Typography
              fontFamily={"inter"}
              fontSize={"12px"}
              fontWeight={500}
              sx={{ bgcolor: "#F8F8F8", py: 1.5, color: "#899CAF", pl: 1 }}
            >
              Appointment Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  fontFamily={"inter"}
                  fontSize={"12px"}
                  fontWeight={500}
                  sx={{ mt: 2, mb: 2, ml: 2 }}
                  id="starick"
                >
                  Select Date
                </Typography>
                {/* ------------------------calender Details------------------------- */}
                <Box sx={{ ml: 2 }}>
                  <Calendar onChange={onChange} value={value} />
                </Box>
                {/* ------------------------calender Details------------------------- */}
              </Grid>
              {/* ------------------------time button Details------------------------- */}
              <Grid item xs={4}>
                <Typography
                  fontFamily={"inter"}
                  fontSize={"12px"}
                  fontWeight={500}
                  sx={{ mt: 2, mb: 2, ml: 1 }}
                  id="starick"
                >
                  Select Time
                </Typography>
                <Box sx={{ ml: 1 }}>
                  <Grid container spacing={1}>
                    {timeConstant.map((tim, inx) => {
                      return (
                        <Grid item xs={4} key={`time-${inx}`}>
                          <Box
                            sx={{
                              "& button": {
                                color: "#475467",
                                border:
                                  selectTime == tim.time
                                    ? "1px solid #1976d2"
                                    : "1px solid #B9C5D275",
                                height: "55px",
                                background:
                                  selectTime == tim.time
                                    ? "rgba(25, 118, 210, 0.04)"
                                    : "transparent",
                              },
                            }}
                          >
                            <Button
                              size="large"
                              onClick={() => handleSelctTime(tim)}
                              variant="outlined"
                            >
                              {tim.time}
                            </Button>
                          </Box>
                        </Grid>
                      );
                    })}
                    {earlyRiser.map((eitem, erlyinx) => {
                      return (
                        <Grid item xs={7} key={`earlyBird-${erlyinx}`}>
                          <Box
                            sx={{
                              "& button": {
                                color: "#475467",
                                border:
                                  eitem?.text == selectEarly?.text
                                    ? "1px solid #1976d2"
                                    : "1px solid #B9C5D275",
                                height: "80px",
                                minWidth: "237px",
                                justifyContent: "flex-start",
                                background:
                                  eitem?.text == selectEarly?.text
                                    ? "rgba(25, 118, 210, 0.04)"
                                    : "#D0D5DD",
                                textTransform: "capitalize",
                              },
                            }}
                          >
                            <Button
                              size="large"
                              variant="outlined"
                              onClick={() => handleEarly(eitem)}
                            >
                              <Box sx={{ mr: 1, mt: 1 }}>
                                {/* <img src="" alt="morning" /> */}
                              </Box>
                              {eitem.text}
                            </Button>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
              {/* ------------------------end time button Details------------------------- */}
              <Grid item xs={4}>
                <Typography
                  fontFamily={"inter"}
                  fontSize={"12px"}
                  fontWeight={500}
                  sx={{ mt: 2, mb: 2 }}
                  id="starick"
                >
                  Appointment Reason
                </Typography>
                <Box
                  sx={{
                    mr: 2,
                    border: "1px solid lightgray",
                    borderRadius: "5px",
                  }}
                >
                  <SuggestionAutoComplete
                    userArr={allReason}
                    onSelect={(selectVehicle) => {
                      setReason(selectVehicle);
                      setReasonSearch(selectVehicle?.title);
                      setReasonSuggestn(false);
                    }}
                    loader={false}
                    search={reasonSearch}
                    searchByfieldName={"title"}
                    setSearch={setReasonSearch}
                    setOpenSuggestion={setReasonSuggestn}
                    vehicleSuggestion={reasonSuggestn}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    mr: 2,
                    border: "1px solid lightgray",
                    borderRadius: "5px",
                  }}
                >
                  <TextField
                    id="outlined-multiline-static"
                    fullWidth
                    multiline
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={7.5}
                    label="Anything else we should know? (optional)"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"end"} sx={{ mr: 2, mt: 4 }}>
            <Box>
              <Button
                sx={{
                  color: "#8B8D90",
                  bgcolor: "#EEEEEE",
                  px: 6,
                  py: 1.5,
                  mr: 2.5,
                }}
              >
                Back
              </Button>
            </Box>
            <Box>
              <Button
                type="submit"
                onClick={() => {
                  if (appointmentState?._id) {
                    handleUpdate();
                  } else {
                    handleSubmit();
                  }
                }}
                sx={{
                  color: "#2E7FFD",
                  bgcolor: "#2E7FFD1F",
                  px: 6,
                  py: 1.5,
                }}
              >
                {appointmentState?._id ? "Update" : "Create"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Creatappointment;
