import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from '@mui/icons-material/Menu'
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Logo, MenuIcon } from "./../../assets/images";
import { useNavigate } from "react-router-dom";
import Logout from "../../shared/Modals/Logout";

// icons
import GridViewIcon from "@mui/icons-material/GridView";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import LogoutIcon from "@mui/icons-material/Logout";

import ListSubheader from "@mui/material/ListSubheader";

import Collapse from "@mui/material/Collapse";
import { PlayArrow } from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawer = () => {
    setOpen(!open);
  };

  let sideBar = [
    {
      name: "Dashboard",
      path: "/",
      icon: <GridViewIcon />,
    },
    {
      name: "Users",
      path: "/users",
      icon: <GroupIcon />,
    },
    {
      name: "Appoinments",
      path: "/appointments",
      icon: <NoAccountsIcon />,
    },
    {
      name: "Offers",
      path: "/offer",
      icon: <MonetizationOnIcon />,
    },
    {
      name: "Blogs",
      path: "/blog",
      icon: <SubscriptionsIcon />,
    },
    {
      name: "Coupons",
      path: "/coupon",
      icon: <SubscriptionsIcon />,
    },
    {
      name: "Shops & Operat. Hours",
      path: "/shop",
      icon: <ArticleIcon />,
    },
    // {
    //   name: 'System Settings',
    //   path: '/privacy',
    //   icon: <PrivacyTipIcon />,
    // },
  ];

  const DashItem = (path) => {
    navigate(path);

    if (width <= 767) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (767 > width) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [width]);

  const handleClickShow = () => {
    setShow(true);
  };

  const handleCloseShow = () => {
    setShow(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} className="drawerBackground">
        <DrawerHeader sx={!open ? { justifyContent: "center" } : ""}>
          {open ? (
            <img
              src={
                "https://res.cloudinary.com/dwcyxi2rq/image/upload/v1676471714/integrity/Integrity-1st-Logo_fhj5es.png"
              }
              alt="Logo"
              height="59"
              style={{ marginTop: "1%", marginLeft: "10%" }}
            />
          ) : (
            ""
          )}
          <IconButton onClick={handleDrawer}>
            <img src={MenuIcon} />
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            height: "88vh",
          }}
        >
          <Box>
            {sideBar.map((item, inx) => (
              <ListItem
                key={inx}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => DashItem(item.path)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    background:
                      item.path == props.path
                        ? "rgb(248, 61, 75)"
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        item.path == props.path
                          ? "rgb(248, 61, 75)"
                          : "transparent",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    style={
                      item.path == props.path
                        ? {
                            margin: "0 auto",
                            // background: 'rgb(248, 61, 75)',
                            padding: "5px",
                            color: "#FFFF",
                            borderTopLeftRadius: "12px",
                            borderBottomLeftRadius: "12px",
                            borderTopRightRadius: !open ? "12px" : "0px",
                            borderBottomRightRadius: !open ? "12px" : "0px",
                          }
                        : {
                            margin: "0 auto",
                            padding: "5px",
                            color: "rgb(128, 129, 145)",
                          }
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    className="listItems"
                    sx={
                      item.path == props.path
                        ? {
                            opacity: open ? 1 : 0,
                            color: "#FFFF",
                            // background: 'rgb(248, 61, 75)',
                            padding: open ? "7px" : "0px",
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                          }
                        : {
                            opacity: open ? 1 : 0,
                            padding: open ? "7px" : "0px",
                            color: "rgb(128, 129, 145)",
                          }
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <Lists parentProps={props} open={open} DashItem={DashItem} />
          <Box
            sx={{
              position: "relative",
              bottom: 0,
              width: "100%",
            }}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => handleClickShow()}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                  style={{
                    margin: "0 auto",
                    padding: "8px",
                    color: "#ff6565",
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                    borderTopRightRadius: !open ? "12px" : "0px",
                    borderBottomRightRadius: !open ? "12px" : "0px",
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Logout"}
                  className="listItems"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "#ff6565",
                    padding: open ? "10px" : "0px",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </Box>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, marginLeft: width < 767 ? "50px" : "0px",overflow:'scroll' }}
      >
        <props.component open={open} />
      </Box>
      <Logout
        open={show}
        handleClickOpen={handleClickShow}
        handleClose={handleCloseShow}
      />
    </Box>
  );
}
const Lists = ({ parentProps, open, DashItem }) => {
  let DropDownArr = [
    {
      name:"Add/Remove Rewards",
      path:"/firsReward",
      icon:<PlayArrow/>
    },
    {
      name: "Dynamic Links",
      path: "/dynamiclinks",
      icon: <PlayArrow />,
    },
    {
      name: "Onboarding Images",
      path: "/onboarding",
      icon: <PlayArrow />,
    },
    {
      name: "Reward amounts",
      path: "/reward",
      icon: <PlayArrow />,
    },
    {
      name: "System Logo",
      path: "/systemLogo",
      icon: <PlayArrow />,
    },
    {
      name: "Media Upload",
      path: "/media",
      icon: <PlayArrow />,
    },
  ];
  const [opens, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!opens);
  };
  return (
    <>
      <ListItemButton style={{width:"100%"}} onClick={handleClick}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
          style={{
            margin: "0 auto",
            padding: "5px",
            color: "rgb(128, 129, 145)",
          }}
        >
          <PrivacyTipIcon />
        </ListItemIcon>
        <ListItemText
          primary="System Settings"
          className="listItems"
          sx={{
            opacity: open ? 1 : 0,
            padding: open ? "7px" : "0px",
            color: "rgb(128, 129, 145)",
          }}
        />
        {opens ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={opens} timeout="auto" unmountOnExit>
        {DropDownArr.map((item, inx) => {
          return (
            <>
              <List key={inx} component="div" disablePadding>
                <ListItemButton
                  // sx={{ pl: 4 }}
                  sx={{
                    pl: 4,
                    minHeight: 48,
                    // width:"100%",
                    // height:55,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    background:
                      item.path == parentProps.path
                        ? "rgb(248, 61, 75) !important"
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        item.path == parentProps.path
                          ? "rgb(248, 61, 75) !important"
                          : "transparent",
                    },
                  }}
                  onClick={() => DashItem(item.path)}
                >
                  <ListItemIcon
                    sx={{
                      // minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    style={
                      item.path == parentProps.path
                        ? {
                            margin: "0 auto",
                            // background: 'rgb(248, 61, 75)',
                            padding: "5px",
                            color: "#FFFF",
                            borderTopLeftRadius: "12px",
                            borderBottomLeftRadius: "12px",
                            borderTopRightRadius: !open ? "12px" : "0px",
                            borderBottomRightRadius: !open ? "12px" : "0px",
                          }
                        : {
                            margin: "0 auto",
                            padding: "5px",
                            color: "rgb(128, 129, 145)",
                          }
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={
                      item.path == parentProps.path
                        ? {
                            opacity: open ? 1 : 0,
                            color: "#FFFF",
                            // background: 'rgb(248, 61, 75)',
                            padding: open ? "7px" : "0px",
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                          }
                        : {
                            opacity: open ? 1 : 0,
                            padding: open ? "7px" : "0px",
                            color: "rgb(128, 129, 145)",
                          }
                    }
                    primary={item.name}
                  />
                </ListItemButton>
              </List>
            </>
          );
        })}
      </Collapse>
      {/* <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"

    >
  
     
    </List> */}
    </>
  );
};
