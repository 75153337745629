import React, { useState, useEffect } from "react";
import "../../styles/table.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UserDetailView from "../../shared/Modals/userViewDetail";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Checkbox from "@mui/material/Checkbox";
import {
  getUserService,
  blockService,
  unblockService,
} from "../../services/userService";
import Badge from "@mui/material/Badge";
import Loader from "../../shared/components/Loader";
import { Sample } from "../../assets/images";
import { toastMessage } from "../../shared/components/Toast";
import RegisterUser from "../../shared/Modals/registerUser";
// import Pagination from "../../shared/components/Pagination";
import { ModeEdit, Visibility, Delete, Add } from "@mui/icons-material";
import { NoData } from "../../assets/images/index";
import { pageLmitConver, useLogoutHelper } from "../../utils/helper";
import { deleteUser } from "../../services/userService";
import useDebounce from "../../hooks/debounce";
import Pagination from "../../shared/components/customPaginate/customPagination.jsx";
import RewardModel from "../../shared/Modals/RewardModel";
import VehicleModel from "../../shared/Modals/VehicleModel";
import DeleteUser from "../../shared/Modals/DeleteUser";

export default function Users() {
  let UserRole = {
    active: "ACTIVE",
    block: "BLOCKED",
  };
  let types = {
    single: "single",
    multiple: "multiple",
  };
  const [openDetail, setOpenDetail] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(true);
  const [delLoader, setDelLoader] = useState(true);
  const [openRegisterBool, setOpenRegister] = useState(false);
  const [users, setUsers] = useState([]);
  const [allId, setAllId] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [active, setActive] = useState(1);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedReward, setSelectedReward] = useState({});
  const [delIndx, setDelIndx] = useState(-1);
  const [page, setPage] = useState(1);
  const [changePage, seChngetPage] = useState(1);
  const [openReward, setOpenReward] = useState(false);
  const [openVehicle, setOpenVehicle] = useState(false);
  const [selectVeh, setSelectVeh] = useState({});
  const [openUserDeletionModal, setOpenUserDeletionModal] = useState(false);
  const [deleteUserDetail, setDeleteUserDetail] = useState();
  const { handleLogout } = useLogoutHelper();

  const handleClickOpenDetail = (user) => {
    setOpenDetail(true);
    setSelectedUser(user);
  };
  const handleClickOpenReward = (userId) => {
    setOpenReward(true);
    setSelectedReward(userId);
  };
  const handleClickOpenVehicle = (user) => {
    setOpenVehicle(true);
    setSelectVeh(user);
  };
  const handleCloseVehicle = () => {
    setOpenVehicle(false);
  };
  const handleClickEditUser = (user) => {
    setOpenRegister(true);
    setSelectedUser(user);
  };
  const handleClickOpenRegister = () => {
    setOpenRegister(true);
  };
  const handleCloseReward = () => {
    setOpenReward(false);
  };
  const handleCloseDetail = () => {
    setOpenDetail(false);
  };
  const handleCloseRegister = () => {
    setOpenRegister(false);
    setSelectedUser({});
  };

  const getUsers = () => {
    let query = `?page=${changePage}&limit=10${
      searchText ? "&search=" + searchText : ""
    }`;
    getUserService(query)
      .then(({ data } = data) => {
        console.log("data", data);
        setUsers(data?.users);
        if (data?.total > 10) {
          setLastPage(pageLmitConver(data?.total));
        } else {
          setLastPage(data?.total);
        }
        // if (searchText) {
        //   seChngetPage(1);
        // }
      })
      .catch((err) => {
        toastMessage(err?.message, "error");
      })
      .finally(() => setLoader(false));
  };

  // useEffect(() => {
  //   setLoader(true);
  //   getUsers();
  // }, []);
  const changCheckBox = (boxId) => {
    let cloneId = [...allId];
    let checkIndx = allId.findIndex((ii) => ii == boxId?._id);
    if (checkIndx > -1) {
      cloneId.splice(checkIndx, 1);
      setAllId(cloneId);
    } else {
      cloneId.push(boxId?._id);
      setAllId(cloneId);
    }
  };
  const selectAll = () => {
    if (allId.length < 1) {
      let allids = users.map((ii) => ii._id);
      setAllId(allids);
    } else {
      setAllId([]);
    }
  };

  const handleBlock = (user, type) => {
    console.log(user, type);
    if (type == UserRole.active) {
      blockService(user?._id)
        .then(({ data } = data) => {
          toastMessage(data?.message);
          toastMessage("User Block successfully", "success");
          let temp = [...users];
          let Index = users.findIndex((i) => i._id == user._id);
          if (Index > -1) {
            temp[Index].userStatus = UserRole.block;
          }
          setUsers(temp);
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
        });
    } else {
      unblockService(user?._id)
        .then(({ data } = data) => {
          toastMessage("User Un Block successfully", "success");
          let temp = [...users];
          let Index = users.findIndex((i) => i._id == user._id);
          if (Index > -1) {
            temp[Index].userStatus = UserRole.active;
          }

          setUsers(temp);
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
        });
    }
  };
  const handleDelete = (type, ids) => {
    let cloneAllOffer = [...users];

    if (type == types.single) {
      setDelLoader(true);

      deleteUser("?userIds=" + ids)
        .then(({ data: { message } }) => {
          let checkIndx = cloneAllOffer.findIndex((ii) => ii?._id == ids);
          if (checkIndx > -1) {
            cloneAllOffer.splice(checkIndx, 1);
            setUsers(cloneAllOffer);
          }
          toastMessage("Users deleted successfully.", "success");
          //remove select id from state
          if (allId?.length) {
            let cloneAllIds = [...allId];
            let checkIdIndx = cloneAllIds.findIndex((ii) => ii == ids);
            if (checkIdIndx > -1) {
              cloneAllIds.splice(checkIdIndx, 1);
              setAllId(cloneAllIds);
            }
          }
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    } else {
      setDelLoader(true);
      deleteUser("?userIds=" + allId?.toString())
        .then(({ data: { message } }) => {
          for (let index = 0; index < allId.length; index++) {
            const element = allId[index];
            let checkIndx = cloneAllOffer.findIndex((ii) => ii?._id == element);
            if (checkIndx > -1) {
              cloneAllOffer.splice(checkIndx, 1);
            }
          }
          setAllId([]);
          setUsers(cloneAllOffer);

          toastMessage("Users deleted successfully.", "success");
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    }
  };
  useDebounce(
    () => {
      setLoader(true);
      getUsers();
    },
    [searchText, changePage],
    800
  );
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleClickYes = () => {
    if (deleteUserDetail?.type) {
      handleDelete(deleteUserDetail.type, deleteUserDetail.id);
    } else {
      handleDelete(types.multiple);
    }
    setOpenUserDeletionModal(false);
    setDeleteUserDetail(null);
  };
  const handleClose = () => {
    setOpenUserDeletionModal(false);
    setDeleteUserDetail(null);
    setDelIndx(-1);
  };
  return (
    <>
      <div className="flex spaceBetween itemCenter">
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "48px",
            letterSpacing: "-0.5px",
            color: "#11142D",
            marginBottom: "2%",
          }}
        >
          All Users
        </Typography>
        <div>
          {allId.length > 1 ? (
            <Button
              sx={{
                background: " rgba(255, 183, 190, 0.9);",
                color: "red",
                marginRight: "10px",
              }}
              onClick={() => setOpenUserDeletionModal(true)}
            >
              <Delete style={{ color: "red" }} />
              Delete
            </Button>
          ) : (
            ""
          )}

          <Button variant="outlined" onClick={handleClickOpenRegister}>
            Register User
          </Button>
        </div>
      </div>

      <TextField
        id="outlined-basic"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: "12px",
            border: "none",
            backgroundColor: "#F8F8F9",
            height: "56px",
          },
          autoComplete: "off",
        }}
        sx={{ width: "100%" }}
        placeholder="Search"
        onChange={(e) => {
          seChngetPage(1);
          setActive(1);
          setSearchText(e.target.value);
        }}
        value={searchText}
        autoComplete="off"
      />
      {loader ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {users.length > 0 ? (
            <>
              <TableContainer
                className="tabledContainer"
                sx={{
                  marginTop: "2%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={allId.length ? true : false}
                          onChange={() => selectAll()}
                          {...label}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Refferedy By</TableCell>
                      <TableCell>Owned Vehicles</TableCell>
                      <TableCell>Current Reward</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Referrals</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            {...label}
                            checked={allId.includes(row?._id)}
                            size="small"
                            onChange={() => changCheckBox(row)}
                          />
                        </TableCell>
                        <TableCell>
                          <div className="tableNameDiv">
                            <div style={{ marginLeft: "10%" }}>
                              <div className="taleName">{row?.phoneNumber}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {row?.referrerUser?.fullName
                            ? row?.referrerUser?.fullName
                            : "--"}
                        </TableCell>
                        <TableCell>
                          {row?.vehicles?.length + " "}
                          {row?.vehicles?.length ? (
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                handleClickOpenVehicle(row?.vehicles)
                              }
                            >
                              View Details
                            </a>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>
                          {row?.rewardAmount == 0
                            ? "$" + row?.rewardAmount + " "
                            : "$" + row?.rewardAmount + " "}
                          <a
                            href="javascript:void(0)"
                            onClick={() => handleClickOpenReward(row?._id)}
                          >
                            Reward History
                          </a>
                        </TableCell>
                        <TableCell>
                          {row?.fullName ? row?.fullName : "--"}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {/* {console.log(row,'<==refer side')} */}
                            {row?.referredUsers?.length
                              ? row?.referredUsers
                                  .filter((itm, dex) => dex <= 1)
                                  .map((refer, rinx) => {
                                    return (
                                      <Badge
                                        style={
                                          row?.referredUsers?.length > 0
                                            ? { margin: "0 55px" }
                                            : {
                                                width: "auto",
                                                wordSpacing: "nowrap",
                                              }
                                        }
                                        // sx={{
                                        //   width: "max-content",
                                        //   margin:"0 50px"
                                        //   fontSize:"5px"
                                        //   height: 25,
                                        // }}
                                        color={"secondary"}
                                        badgeContent={refer?.fullName}
                                        max={rinx}
                                      ></Badge>
                                    );
                                  })
                              : "--"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div style={{ display: "flex" }}>
                            <Button
                              variant="outlined"
                              sx={{
                                minWidth: "43px",
                                borderColor: "#c8bcbc !important",
                                "&:hover": {
                                  background: "#FEE8CE",
                                },
                              }}
                              onClick={() => handleClickOpenDetail(row)}
                            >
                              <Visibility style={{ color: "#FD8A25" }} />
                            </Button>

                            {/* Edit button */}

                            <Button
                              variant="outlined"
                              sx={{
                                marginLeft: "5%",
                                marginRight: "5%",
                                minWidth: "43px",
                              }}
                              onClick={() => handleClickEditUser(row)}
                            >
                              <ModeEdit />
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{
                                minWidth: "43px",
                                borderColor:
                                  "rgba(255, 183, 190, 0.9) !important",
                                "&:hover": {
                                  background: " rgba(255, 183, 190, 0.9);",
                                },
                              }}
                              onClick={() => {
                                setDelIndx(index);
                                setDeleteUserDetail({
                                  type: types.single,
                                  id: row?._id,
                                });
                                // handleDelete(types.single, row?._id);
                                setOpenUserDeletionModal(true);
                              }}
                              // delete button
                            >
                              {delLoader && index == delIndx ? (
                                <Loader colors={"red"} />
                              ) : (
                                <Delete style={{ color: "red" }} />
                              )}
                            </Button>
                            {row?.userStatus == UserRole.active ? (
                              <Button
                                sx={{
                                  background: " rgba(207 , 255, 204, 0.9);",

                                  marginLeft: "5%",
                                }}
                                onClick={() =>
                                  handleBlock(row, UserRole.active)
                                }
                              >
                                <LockOpenIcon style={{ color: "green" }} />
                              </Button>
                            ) : (
                              <Button
                                sx={{
                                  background: " rgba(255, 183, 190, 0.9);",
                                  marginLeft: "5%",
                                }}
                                onClick={() => handleBlock(row, UserRole.block)}
                              >
                                <LockPersonIcon style={{ color: "red" }} />
                              </Button>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                listSize={lastPage}
                page={active}
                setPage={setActive}
                seChngetPage={seChngetPage}
                lastPage={lastPage}
              />
              {/* <Pagination
                lastPage={lastPage}
                active={active}
                setActive={setActive}
              /> */}
            </>
          ) : (
            <div className="flexCard" style={{ marginTop: "10%" }}>
              <img src={NoData} />
            </div>
          )}
        </>
      )}

      {/* delete user modal box */}
      {openUserDeletionModal && (
        <DeleteUser
          open={openUserDeletionModal}
          handleClickYes={handleClickYes}
          handleClose={handleClose}
        />
      )}

      {openDetail ? (
        <UserDetailView
          detail={selectedUser}
          setDetail={setSelectedUser}
          open={openDetail}
          handleClose={handleCloseDetail}
        />
      ) : (
        ""
      )}

      {openRegisterBool ? (
        <RegisterUser
          selectedUser={selectedUser}
          open={openRegisterBool}
          allUsers={users}
          setUsers={setUsers}
          handleClose={handleCloseRegister}
        />
      ) : (
        ""
      )}
      {openReward ? (
        <RewardModel
          open={openReward}
          setReward={setSelectedReward}
          reward={selectedReward}
          handleClose={handleCloseReward}
        />
      ) : (
        ""
      )}
      {openVehicle ? (
        <VehicleModel
          open={openVehicle}
          setVehicle={setSelectVeh}
          vehicle={selectVeh}
          handleClose={handleCloseVehicle}
        />
      ) : (
        ""
      )}
    </>
  );
}
