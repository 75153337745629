import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

function Pagination({ lastPage, active, setActive }) {
  const numbers = Array.from({ length: lastPage }, (_, index) => index + 1)

  const handleLastClick = () => {
    if (active > 1) {
      setActive(active - 1)
    }
  }

  const handleNextClick = () => {
    if (lastPage > active) {
      setActive(active + 1)
    }
  }

  return (
    <div className="pagination-container">
      <div className="tablePaginate" onClick={handleLastClick}>
        <KeyboardArrowLeftIcon />
      </div>
      {numbers.map((number, inx) => (
        <div
          key={inx}
          className={
            number === active ? 'paginateActive tablePaginate' : 'tablePaginate'
          }
          onClick={() => setActive(number)}
        >
          {number}
        </div>
      ))}
      <div className="tablePaginate" onClick={handleNextClick}>
        <KeyboardArrowRightIcon />
      </div>
    </div>
  )
}

export default Pagination

