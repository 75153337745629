import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LockOpenIcon from '@mui/icons-material/LockOpen'
// import LogoutIcon from '@mui/icons-material/Logout'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import LogoutModal from './../Modals/Logout'
import { LogoutSvg } from './../../assets/images/index'
import Notificat from '../Modals/Notificat'

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [show, setShow] = useState(false)
  const [showCPModal, setCPShow] = useState(false)



  const handleCloseCPShow = () => {
    setCPShow(false)
  }

  const handleClickShow = () => {
    setShow(true)
    handleClose()
  }

  const handleCloseShow = () => {
    setShow(false)
  }
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '24px',
        paddingRight: '24px',
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          background: '#ececec',
          color: 'grey',
          textTransform: 'none',
          borderRadius: '12px',
        }}
      >
        <PersonOutlineIcon />
        <span style={{ marginRight: '20px', padding: '5px' }}>Admin</span>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="MenuListing"
      >
        {/* <MenuItem
          // onClick={handleClickShow}
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: '#FF754C',
          }}
          className="MenuItems"
        >
          <Notificat/>
          
        </MenuItem> */}
        <MenuItem
          onClick={handleClickShow}
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: '#FF754C',
          }}
          className="MenuItems"
        >
          <img src={LogoutSvg} style={{ marginRight: '10px' }} />
          Logout
        </MenuItem>
      </Menu>
      <LogoutModal
        open={show}
        handleClickOpen={handleClickShow}
        handleClose={handleCloseShow}
      />
  
    </div>
  )
}
