const openHours = [
    {
      from: "",
      till: "",
    },
    {
      from: "",
      till: "",
    },
    {
      from: "",
      till: "",
    },
    {
      from: "",
      till: "",
    },
    {
      from: "",
      till: "",
    },
    {
      from: "",
      till: "",
    },
    {
      from: "",
      till: "",
    },
  ]
  export {
    openHours
  }