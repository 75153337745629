import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Loader from '../shared/components/Loader'
import { useDispatch } from 'react-redux'
import { loginService } from './../services/authService'
import { login } from './../shared/redux/userSlice'
import { Logo } from './../assets/images'
import { toastMessage } from './../shared/components/Toast'

export default function Login() {
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = () => {
    if (email == '' || password == '') {
      toastMessage('Please enter all fields', 'error')
    } else {
      setLoader(true)
      let obj = {
        phoneNumber:"+1"+email,
        password:password
      }
      loginService(obj)
        .then(({ data }) => {
          setLoader(false)
          dispatch(login(data))
        })
        .catch((err) => {
          setLoader(false)
          toastMessage(err.response.data.message, 'error')
        })
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const changeTitle = () => {
    document.title = 'Auto Motive'
  }
  useEffect(() => {
    changeTitle()
  }, [])

  return (
    <div className="background">
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Card sx={{ minWidth: '594px' }} className="cardContainer">
          <CardContent>
            <Container
              sx={{
                width: '90%',
                paddingTop: '36px',
                paddingBottom: '36px',
              }}
              className="containerLogin"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '5%',
                }}
              >
                <img src={"https://res.cloudinary.com/dwcyxi2rq/image/upload/v1676471714/integrity/Integrity-1st-Logo_fhj5es.png"} height="108" />
              </div>

              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 25,
                  lineHeight: '48px',
                  letterSpacing: '-0.5px',
                  color: '#11142D',
                }}
                className="heading"
                color="text.secondary"
                gutterBottom
              >
                Welcome!
              </Typography>

              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#11142D',
                  marginTop: '20px',
                  marginBottom: '5px',
                }}
              >
                Phone Number
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: 'none',
                    backgroundColor: '#F8F8F9',
                    height: '56px',
                  },
                }}
                sx={{ width: '100%' }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onKeyDown={handleKeyDown}
              />
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#11142D',
                  marginTop: '20px',
                  marginBottom: '5px',
                }}
              >
                Password
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: 'none',
                    backgroundColor: '#F8F8F9',
                    height: '56px',
                  },
                  endAdornment: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        height: '100%',
                      }}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </Box>
                  ),
                }}
                sx={{ width: '100%' }}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                sx={{
                  backgroundColor: 'rgb(248, 61, 75)',
                  width: '100%',
                  textTransform: 'none',
                  height: '56px',
                  marginTop: '25px',
                  '&:hover': {
                    backgroundColor: 'rgb(21, 101, 192)',
                  },
                }}
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {loader ? <Loader /> : 'Login'}
              </Button>
            </Container>
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}
