import * as yup from "yup";
let urlRegex = '((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)'
const createOffer = yup.object().shape({
    title: yup.string().required("offer title is required").label("title"),
    url: yup.string().required("offer url is required").label("url"),
    type:yup.string().required("click type is required").label("type"),
    dis:yup.number().min(0).required("offer discount is required").label("dis"),
    oferImage:yup.string().required("offer image is required").label("oferImage"),
    offerImageMobile:yup.string().required("offer image url for mobile is required").label("offerImageMobile"),
    terms:yup.string().required("offer terms & condition is required").label("terms"),
  });
const createBlog = yup.object().shape({
    title: yup.string().required("offer title is required").label("title"),
    url: yup.string().required("offer url is required").label("url"),
    type:yup.string().required("click type is required").label("type"),
    oferImage:yup.string().required("offer image is required").label("oferImage"),
    terms:yup.string().required("offer terms & condition is required").label("terms"),
  });
  const dynamicVS =  yup.object().shape({
    fcbLink: yup.string().required("Facebook Link is required").label("fcbLink"),
    instLink: yup.string().required("Instagram Link is required").label("instLink"),
    linkedInLink:yup.string().required("linkedIn Link is required").label("linkedInLink"),
    policyLink:yup.string().required("Privacy & Policy Link is required").label("policyLink"),
    contact:yup.string().required("Contact Us Link is required").label("contact"),
  });
  const logoVS = yup.object().shape({
    logo: yup.string().required("System Logo is required").label("logo"),
  })
  export{
    createOffer,
    createBlog,
    dynamicVS,
    logoVS
  }