import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Loader from "./../components/Loader";
import { CrossImage } from "./../../assets/images/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ContentCopy } from "@mui/icons-material";
import { changePasswordService } from "../../services/authService";
import { toastMessage } from "./../components/Toast";
// import InputMask from "react-input-mask";
import { createCouponServ } from "../../services/couponService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateCoupon({
  open,
  handleClose,
  allUsers,
  setUsers,
  selectedCoupon,
}) {
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState("");
  const [status, setStatus] = useState("active");
  const [reward, setReward] = useState("");
  const [redirectUrl, setUrl] = useState("");
  const [redirectStatus, setRedirectStatus] = useState("active");

  const handleSubmit = () => {
    if (!code) {
      toastMessage("Coupon code is required", "error");
    } else if (!status) {
      toastMessage("Status is required", "error");
    } else if (!reward) {
      toastMessage("Reward is required", "error");
    } else if (redirectStatus === "active" && !redirectUrl) {
      toastMessage("URL cannot be empty when redirect status is active", "error");
    } else {
      let obj = {
        status: status,
        reward: reward,
        code: code,
        redirectUrl: redirectUrl,
        redirectStatus: redirectStatus,
      };
  
      setLoader(true);
      createCouponServ(obj)
        .then(({ data }) => {
          let cloneAllUser = [...allUsers];
          cloneAllUser = [data, ...cloneAllUser];
          setUsers(cloneAllUser);
          resetState();
          handleClose();
          toastMessage("Coupon Added Successfully", "success");
        })
        .catch((err) => {
          debugger;
          toastMessage(err?.response?.data?.message || err?.message, "error");
        })
        .finally(() => setLoader(false));
    }
  };
  

  const resetState = () => {
    setCode("");
    setStatus("");
    setReward("");
  };

  useEffect(() => {
    setCode(selectedCoupon?.code || "");
    setStatus(selectedCoupon?.status || "");
    setReward(selectedCoupon?.reward || "");
  }, [selectedCoupon]);

  const checkSelectuser = Object.keys(selectedCoupon).length ? true : false;

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <Container sx={{ paddingTop: "40px", paddingBottom: "40px" }}>
          <DialogTitle
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "48px",
              letterSpacing: "-0.5px",
              color: "#11142D",
            }}
            className="heading"
            color="text.secondary"
          >
            {checkSelectuser ? "Update" : "Add Coupon"}
          </DialogTitle>
          <img
            src={CrossImage}
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <DialogContent>
            <TextFieldTitle text={"Coupon code:"} />
            <TextFieldInput setState={setCode} state={code} />

            <TextFieldTitle text={"Status:"} />
            <SelectInputComp
              list={["active", "inactive"]}
              selectStatus={status}
              selectSelectStatus={setStatus}
            />
            <TextFieldTitle text={"Reward:"} />
            <TextFieldInput setState={setReward} state={reward} />
            <TextFieldTitle text={"Redirect Url:"} />
            <TextFieldInput setState={setUrl} state={redirectUrl} />
            <TextFieldTitle text={"Redirect Status:"} />
            <SelectInputComp
              list={["active", "inactive"]}
              selectStatus={redirectStatus}
              selectSelectStatus={setRedirectStatus}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#6C5DD3",
                boxShadow: "none",
                height: "56px",
                "&:hover": {
                  backgroundColor: "#6C5DD3",
                },
              }}
            >
              {loader ? <Loader /> : checkSelectuser ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}

const TextFieldInput = ({ setState, state }) => {
  return (
    <TextField
      InputProps={{
        style: {
          borderRadius: "12px",
          border: "none",
          backgroundColor: "#F8F8F9",
          height: "56px",
        },
      }}
      sx={{ width: "100%" }}
      value={state}
      onChange={(e) => setState(e.target.value)}
    />
  );
};

const TextFieldTitle = ({ text }) => {
  return (
    <Typography
      sx={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#11142D",
        marginTop: "20px",
        marginBottom: "5px",
      }}
    >
      {text}
    </Typography>
  );
};

const SelectInputComp = ({ list, selectStatus, selectSelectStatus }) => {
  const handleChange = (event) => {
    selectSelectStatus(event.target.value);
  };
  return (
    <Select
      value={selectStatus}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      style={{
        borderRadius: "12px",
        border: "none",
        backgroundColor: "#F8F8F9",
        height: "56px",
        width: "100%",
      }}
    >
      <MenuItem value="">
        <em>Select status</em>
      </MenuItem>
      {list?.map((item, inx) => {
        return (
          <MenuItem key={inx} value={item}>
            {item}
          </MenuItem>
        );
      })}
    </Select>
  );
};
