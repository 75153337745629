import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { publicRoutes, privateRoutes } from "./allRoutes";
import Layout from './Layout';
import { useSelector } from "react-redux";
import Login from "./../pages/Login"
import ErrorPage from "./../pages/Error"
import {Navigate} from 'react-router-dom'



function AllRoutes() {

  const user = useSelector((state) => state.root.user.user)
  const location = useLocation();

  return (
    <>
      <Routes>
        {user ?
          privateRoutes.map((item, inx) => {
            return (
              <>
                <Route
                  key={inx}
                  path={item.path}
                  exact={true}
                  element={<Layout {...item} />}
                />
              </>
            )
          })
          :
          publicRoutes.map((item, inx) => {
            return (
              <>
                <Route
                  key={inx}
                  path={item.path}
                  exact={true}
                  element={<Login />}
                />
              </>
            )
          })}
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Routes>
    </>
  )
}

export default AllRoutes

