import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { Container } from '@mui/system'
import { CrossImage } from './../../assets/images/index'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../shared/redux/userSlice'
import { logoutService } from '../../services/authService'
import { toastMessage } from '../../shared/components/Toast'
import Loader from '../components/Loader'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
}) {
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    // setLoader(true)
    dispatch(logout())
    // logoutService()
    //   .then(({ data } = data) => {
    //     setLoader(false)
    //     toastMessage(data?.message)
        
    //     navigate('/')
    //   })
    //   .catch((err) => {
    //     setLoader(false)
    //     toastMessage(err?.response?.data?.message, 'error')
    //   })
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        <Container
          sx={{
            paddingTop: '40px',
            paddingBottom: '40px',
          }}
        >
          <DialogTitle
            sx={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 32,
              lineHeight: '48px',
              letterSpacing: '-0.5px',
              color: '#11142D',
            }}
            className="heading"
            color="text.secondary"
          >
            Logout?
          </DialogTitle>
          <img
            src={CrossImage}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          />
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                color: '#11142D',
              }}
            >
              Are you sure, you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: '#F0F3F6',
                color: '#808191',
                boxShadow: 'none',
                borderRadius: '10px',
                height: '56px',
                '&:hover': {
                  backgroundColor: '#F0F3F6',
                },
              }}
            >
              No
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleLogout()}
              sx={{
                backgroundColor: '#F53649',
                boxShadow: 'none',
                height: '56px',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F53649',
                },
              }}
            >
              {loader ? <Loader /> : 'Yes'}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  )
}
