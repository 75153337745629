import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { Formik } from "formik";
import Loader from "../../../shared/components/Loader";
import { useNavigate } from "react-router-dom";
import {
  listDynamicURl,
  updateDynamicURl,
} from "../../../services/settingService";
import { toastMessage } from "../../../shared/components/Toast";
function Onboarding() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [boarding, setboarding] = useState("");
  useEffect(() => {
    let cloneState = {};
    let obj = {};
    let query = "onBoardings";
    listDynamicURl(query)
      .then(({ data }) => {
        let selectedKey = [
          {
            key: "onBoardings",
            statePropty: "boarding",
          },
        ];

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let findKey = selectedKey.find((ii) => ii.key === element?.key);
          if (findKey && Object.keys(findKey)?.length) {
            obj = {
              ...obj,
              [findKey.statePropty]: element.value,
            };
          }
        }
        obj = {
          ...cloneState,
          ...obj,
        };
        setboarding(JSON.parse(obj?.boarding));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  const handleChange = (indx, key, value) => {
    let clone = [...boarding];
    clone[indx][key] = value;
    clone[indx]["isPublic"] = false; 
    setboarding(clone);
  };
  const handleUpdate = () => {
    if (boarding && boarding?.length) {
      let obj = {
        value: JSON.stringify(boarding),
      };
      updateDynamicURl("onBoardings",obj)
        .then(({ data }) => {
          toastMessage("Dynamic key value is successfully updated", "success");
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
        });
    }
  };
  return (
    <Box className="">
      <Box
        borderBottom={"1px solid #B9C5D275"}
        flexDirection={"column"}
        display={"flex"}
      >
        <Typography
          variant="h5 "
          fontFamily={"inter"}
          fontSize={"20px"}
          fontWeight={600}
          sx={{ mb: 1, mt: 3, ml: 3, color: "#2F496C" }}
        >
          Onboarding Images
        </Typography>

        <Typography
          variant="body6"
          fontFamily={"inter"}
          fontSize={"12px"}
          fontWeight={400}
          sx={{ mb: 2.5, ml: 3, color: "#899CAF" }}
        >
          one place for Editing Company Logo all over the app
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Box sx={{ mt: 5, pb: 5 }} border={"1px solid #B9C5D275"}>
          <Box bgcolor={"#F8F8F8"} sx={{ py: 2 }}>
            <Typography variant="body5" sx={{ ml: 2 }}>
              Public Onboarding Images
            </Typography>
          </Box>
          {/*-------------------- input ----------------------*/}
          {boarding &&
            boarding?.map((item, inx) => {
              return (
                <div className="onboardingContainer">
                  <label className="onbardForm">Form #{inx+1}</label>
                  <InputWithTitle
                    title={"Title"}
                    onChange={(e) =>
                      handleChange(inx, "title", e?.target?.value)
                    }
                    value={item?.title}
                  />
                  <InputWithTitle
                    title={"Description"}
                    onChange={(e) =>
                      handleChange(inx, "description", e?.target?.value)
                    }
                    value={item?.description}
                  />
                  <InputWithTitle
                    title={"Image"}
                    onChange={(e) =>
                      handleChange(inx, "image", e?.target?.value)
                    }
                    value={item?.image}
                  />
                </div>
              );
            })}
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"end"}
        sx={{ mr: 4.5, mt: 4, pb: 5 }}
      >
        <Box>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              color: "#8B8D90",
              bgcolor: "#EEEEEE",
              px: 6,
              py: 1.5,
              mr: 2.5,
            }}
          >
            Cancel
          </Button>
        </Box>

        <Box>
          <Button
            disabled={loading}
            sx={{
              color: "#2E7FFD",
              bgcolor: "#2E7FFD1F",
              px: 6,
              py: 1.5,
            }}
            onClick={handleUpdate}
          >
            {loading ? <Loader colors={"#2278CF"} /> : "Save Changes"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
const InputWithTitle = ({ title, onChange, value, placeholder }) => {
  return (
    <Box sx={{ px: 3 }}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body5"
              fontFamily={"inter"}
              fontSize={"12px"}
              fontWeight={500}
              color={"#899CAF"}
              sx={{ ml: 0.7 }}
              id="starick"
            >
              {title}
            </Typography>
          </Box>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { mt: 1.5 },
              "& .MuiInputBase-root": {
                height: 50,
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              name="offer_tital"
              sx={{
                width: "100%",
                "& > :not(style)": { width: "100%", border: "1px solid" },
              }}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Onboarding;
