import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";

const listAllOffer = (query) => {
  
  return HTTP_Request.get(BASE_URL + Endpoints.offer.list+query);
}
const createOfferServ = (obj) => {
  
  return HTTP_Request.post(BASE_URL + Endpoints.offer.create,obj);
}
const updateOfferService = (offerId,obj) => {
  return HTTP_Request.patch(BASE_URL + Endpoints.offer.update+offerId,obj);
  
//  return new Promise((resolve,reject)=>{
//     setTimeout(() => {
//       resolve("done")
//     }, 3000);
//   })
}
const deleteOffer = (offerids) => {
  
  return HTTP_Request.delete(BASE_URL + Endpoints.offer.deleteOffer+"/"+offerids);
}


export { listAllOffer,createOfferServ,deleteOffer,updateOfferService };
