import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { Container } from '@mui/system'
import { CrossImage, Sample,upArrow,downArrow } from '../../assets/images'
import { Typography } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react'
import { RewardService } from '../../services/rewardService'
import { useState } from 'react';
import moment from 'moment/moment';
import Loader from '../components/Loader'
import { NoData } from '../../assets/images'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function RewardModel({
  open,
  handleClose,
  setReward,
  reward,
}) {
  const onClose = () => {
    setReward({})
    handleClose()
  }
//   console.log('detail',detail);
const [resultReward,setResultReward]=useState('');
const [loader,setLoader]=useState(true);
function createData(name, calories, fat) {
    return { name, calories, fat };
  }
//   const rows = [
//     createData('Frozen yoghurt', 159, 6.0,),
//     createData('Ice cream sandwich', 237, 9.0),
//     createData('Eclair', 262, 16.0),
//     createData('Cupcake', 305, 3.7),
//     createData('Gingerbread', 356, 16.0),
//   ];
  useEffect(()=>{
    let accessReward=`?userId=${reward}`
    RewardService(accessReward).then(({data:data})=>{
        console.log(data,'<==data in reward side')
        setResultReward(data)
    }).finally(()=>setLoader(false))
  },[])
  console.log(resultReward,'<==resultReward')

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogContainer"
      >
        {/* <Container
          sx={{
            paddingTop: '40px',
            paddingBottom: '40px',
          }}
        >
          <img
            src={CrossImage}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
            onClick={() => handleClose()}
          />
          <DialogTitle
            sx={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 32,
              lineHeight: '48px',
              letterSpacing: '-0.5px',
              color: '#11142D',
              display: 'flex',
              justifyContent: 'center',
            }}
            className="heading"
            color="text.secondary"
          >
            Reward History
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={
                detail?.imageURL ? detail?.imageURL 
                                   : Sample
                }
                height="200"
                width="200"
                style={{ borderRadius: '50%' }}
              />
            </div>
            <div style={{ marginTop: '3%', marginBottom: '5%' }}>
              <div className="flexCard userTitle" >
                {detail?.fullName}
              </div>
              <div className="flexCard userName">
                {detail?.phoneNumber}
              </div>
              
            </div>
            <Container>
                <DetailSection
                  heading="Email"
                  details={
                   detail?.email ? detail?.email:"--"
                  }
                />
              <DetailSection
                heading="Reffered By"
                details={
                 "--"
                }
              />
              <DetailSection
                heading="Owned Vehicles"
                details={
                 "--"
                }
              />
              <DetailSection
                heading="Current Reward"
                details={
                 detail?.rewardAmount
                }
              />
            </Container>
            <h6>Refferals</h6>
            <div className="flexCard userName">
                {detail?.phoneNumber}
              </div>
           
          </DialogContent>
        </Container> */}
        <img
            src={CrossImage}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
            onClick={() => handleClose()}
          />
        <DialogTitle
            sx={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 32,
              lineHeight: '48px',
              letterSpacing: '-0.5px',
              color: '#11142D',
              display: 'flex',
              justifyContent: 'center',
            }}
            className="heading"
            color="text.secondary"
          >
            Reward History
          </DialogTitle>
       {loader ? 
       <div
       style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
     >
       <Loader />
     </div>:resultReward.length ? (
        <>
       <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align='center'></TableCell>
            <TableCell align="row" >History</TableCell>
            <TableCell >Amount</TableCell>
            <TableCell align="row">Date</TableCell>
       
          </TableRow>
        </TableHead>
        <TableBody>
          {resultReward.length ? 
          resultReward.map((row) => (
            <TableRow
              key={row}
              sx={{ '&:last-child td, &:last-child th': { border: 0,marginTop:"9px" } }}
            >
                <TableCell align='center'>
                  {console.log(row,'<==row history')}
            {row?.kind==='REWARD_REDEEMED' ? 
              <img
            src={upArrow}
            style={{
           
              cursor: 'pointer',
            }}
         
          />:
          <img
            src={downArrow}
            style={{
         
              cursor: 'pointer',
            }}
           
          />
            }
                </TableCell>
              <TableCell  component="th" scope="center" style={row?.kind==='REWARD_REDEEMED' ?{color:'red'} :{ width: 250 }}>
                {row?.kind}
              </TableCell>
              <TableCell component="th" scope="row" style={row?.kind==='REWARD_REDEEMED' ?{color:'red'} :{ width: 250 }}>  {row?.amount}</TableCell>
              <TableCell align="row" style={row?.kind==='REWARD_REDEEMED' ? {color:'red'} :{ width: 250 }}>{moment(row?.createdAt).format("MMM Do YYYY")}</TableCell>
              {/* <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          )):''
          }
        </TableBody>
      </Table>
    </TableContainer>
        </>
     )
    //    resultReward.length
    :(
        <div className="flexCard" style={{ marginTop: "10%",height:250 }}>
              <img src={NoData} />
            </div>
    )
    }
      </Dialog>
    </div>
  )
}
// const DetailSection = ({ heading, details }) => {
//     return (
//       <div className="flexContainer detailContainer flexModal">
//         <Typography
//           sx={{
//             fontStyle: 'normal',
//             fontWeight: 600,
//             fontSize: '16px',
//             lineHeight: '19px',
//           }}
//         >
//           {heading}
//         </Typography>
//         <Typography
//           sx={{
//             fontStyle: 'normal',
//             fontWeight: 400,
//             fontSize: '18px',
//             lineHeight: '24px',
//             textAlign: 'right',
//             color: '#808191',
//           }}
//         >
//           {details}
//         </Typography>
//       </div>
//     )
//   }