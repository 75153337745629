import { Box, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { toastMessage } from './Toast'
import { Typography } from '@mui/material'
import { useLogoutHelper } from '../../utils/helper'
import Loader from './Loader'

export default function MyEditor({ text, setText, getAPI, updateAPI, type }) {
  const [loader, setLoader] = useState(false)
  const [editorLoader, setEditorLoader] = useState(false)
  function handleTextChange(value) {
    setText(value)
  }

  const { handleLogout } = useLogoutHelper()

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ]

  const getText = () => {
    setEditorLoader(true)
    getAPI()
      .then(({ data } = data) => {
        setEditorLoader(false)
        if (type == 'terms') {
          setText(data.data?.terms_and_conditions?.description)
        } else {
          setText(data?.data?.privacy_policy?.description)
        }
      })
      .catch((err) => {
        setEditorLoader(false)
        console.log('err', err)
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getText()
  }, [])

  const handleUpdate = () => {
    let obj = {
      description: text,
    }

    if (type == 'terms') {
      obj['title'] = 'Terms & Conditions'
    } else {
      obj['title'] = 'Privacy Policy'
    }

    setLoader(true)
    updateAPI(obj)
      .then(({ data } = data) => {
        setLoader(false)
        toastMessage(data?.message)
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.response?.message, 'error')
      })
  }

  return (
    <div>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 32,
          lineHeight: '48px',
          letterSpacing: '-0.5px',
          color: '#11142D',
          marginBottom: '2%',
        }}
      >
        {type == 'terms' ? 'Terms and Condition' : 'Privacy Policy'}
      </Typography>
      {editorLoader ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </Box>
      ) : (
        <ReactQuill
          value={text}
          onChange={handleTextChange}
          modules={modules}
          formats={formats}
        />
      )}
      <Button
        variant="contained"
        fullWidth
        sx={{
          textTransform: 'none',
          background: '#6C5DD3',
          marginTop: '2%',
          height: '40px',
          '&:hover': {
            backgroundColor: '#6C5DD3',
          },
        }}
        onClick={() => handleUpdate()}
      >
        {loader ? <Loader /> : 'Update'}
      </Button>
    </div>
  )
}
