let BASE_URL = "https://api.integrity1auto.com/";
// let BASE_URL = "http://localhost:3002/";

const dynamicKey = {
  linkedIn: "linkedInUrl",
  instagram: "instaUrl",
  facebook: "facebookUrl",
  privacy: "PrivacyPolicy",
  contact: "ContactUs",
  referalBonus: "REFERRAL_BONUS_VALUE",
  loyalReaward: "LOYALTY_BONUS_VALUE",
  inAppLogo: "InAppLogo",
  boarding: "onBoardings",
};
let timeConstant = [
  {
    time: "8:00 am",
    value: 8,
  },
  {
    time: "9:00 am",
    value: 9,
  },
  {
    time: "10:00 am",
    value: 10,
  },
  {
    time: "11:00 am",
    value: 11,
  },
  {
    time: "12:00 am",
    value: 12,
  },
  {
    time: "01:00 pm",
    value: 13,
  },
  {
    time: "02:00 pm",
    value: 14,
  },
  {
    time: "03:00 pm",
    value: 15,
  },
  {
    time: "04:00 pm",
    value: 16,
  },
  {
    time: "05:00 pm",
    value: 17,
  },
  {
    time: "06:00 pm",
    value: 18,
  },
];
let earlyRiser = [
  {
    text: "Early Bird",
    value: true,
  },
  {
    text: "After hours Dropoff",
    value: true,
  },
];

let earlyEnum = {
  early: "Early Bird",
  late: "After hours Dropoff",
};
export { BASE_URL, dynamicKey, timeConstant, earlyRiser, earlyEnum };
