import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const mediaUpload = (formBody) => {
  
  return HTTP_Request.post(BASE_URL + Endpoints.media.upload,formBody);
}



export { mediaUpload };
