import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../shared/redux/userSlice'
import { toastMessage } from '../shared/components/Toast'

const useLogoutHelper = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
    toastMessage('Unathenticated request generated', 'error')
  }

  return { handleLogout }
}
const pageLmitConver = (totalDocumentCount)=>{
  let num = totalDocumentCount/10
 if(Number.isInteger(num)) {
return   num
 }else{
   let firstNumb = Number(String(num).split(".")[0]);
   let secondNUmb = Number(String(num).split(".")[1])
  return  firstNumb+((secondNUmb+(10-secondNUmb))/10)
 }
 
}
function isNumberCheck(e) {
  e = e || window.event;
  var charCode = e.which ? e.which : e.keyCode;
  return /\d/.test(String.fromCharCode(charCode));
}

export { useLogoutHelper,pageLmitConver,isNumberCheck }
