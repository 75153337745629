import React, { useState, useEffect } from "react";
import "../../styles/table.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  listAllCoupon,
  deleteCoupon,
  updateCouponService,
} from "../../services/couponService";
import Loader from "../../shared/components/Loader";
import { Sample } from "../../assets/images";
import Pagination from "../../shared/components/Pagination";
import { NoData } from "../../assets/images/index";
import { toastMessage } from "../../shared/components/Toast";
import { useLogoutHelper } from "../../utils/helper";
import { ModeEdit, Delete, Visibility, Add } from "@mui/icons-material";
import ViewDetail from ".././../shared/Modals/ViewDetails";
import ChangePassword from ".././../shared/Modals/ChangePassword";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/debounce";
import CreateCoupon from "../../shared/Modals/createCoupon";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function Coupon() {
  let types = {
    single: "single",
    multiple: "multiple",
  };
  let statuses = {
    active: "active",
    block: "inactive",
  };
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(true);
  const [allOffers, setAllOffer] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [active, setActive] = useState(1);
  const [allId, setAllId] = useState([]);
  const [offerDetail, setOfferDetail] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [delLoader, setDelLoader] = useState(false);
  const [delIndx, setDelIndx] = useState(-1);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState(null); // Store items to delete after confirmation

  const { handleLogout } = useLogoutHelper();

  const getDonor = () => {
    setLoader(true);
    let query = `${searchText ? "?search=" + searchText : ""} `;
    listAllCoupon(query)
      .then(({ data } = data) => {
        setLoader(false);
        console.log("data", data);
        setLastPage(data?.total);
        setAllOffer(data?.coupons);
      })
      .catch((err) => {
        toastMessage(err?.message, "error");
        setLoader(false);
        if (err?.response?.status == 401) {
          handleLogout();
        }
      });
  };

  const handleButtonClick = (id, newStatus) => {
    updateCouponService(id, { status: newStatus })
      .then(() => {
        const index = allOffers.findIndex((coupon) => coupon._id === id);
        if (index !== -1) {
          const updatedOffers = [...allOffers];
          updatedOffers[index].status = newStatus;
          setAllOffer(updatedOffers);
        }
        toastMessage(`Status updated to ${newStatus} successfully!`);
      })
      .catch((error) => {
        toastMessage("There was an error updating the status.", "error");
      });
  };

  const handleRedirectUpdate = (id, newStatus) => {
    updateCouponService(id, { redirectStatus: newStatus })
      .then(() => {
        const index = allOffers.findIndex((coupon) => coupon._id === id);
        if (index !== -1) {
          const updatedOffers = [...allOffers];
          updatedOffers[index].redirectStatus = newStatus;
          setAllOffer(updatedOffers);
        }
        toastMessage(`Redirect Status updated to ${newStatus} successfully!`);
      })
      .catch((error) => {
        toastMessage(
          "There was an error updating the redirect status.",
          "error"
        );
      });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const changCheckBox = (boxId) => {
    let cloneId = [...allId];
    let checkIndx = allId.findIndex((ii) => ii == boxId?._id);
    if (checkIndx > -1) {
      cloneId.splice(checkIndx, 1);
      setAllId(cloneId);
    } else {
      cloneId.push(boxId?._id);
      setAllId(cloneId);
    }
  };
  // useEffect(() => {

  //   getDonor();
  // }, [ active]);
  const selectAll = () => {
    if (allId.length < 1) {
      let allids = allOffers.map((ii) => ii._id);
      setAllId(allids);
    } else {
      setAllId([]);
    }
  };
  const handleClickOpenDetail = (user) => {
    setOpenDetail(true);
    setOfferDetail(user);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };
  const handleDelete = (type, ids) => {
    setItemsToDelete({ type, ids });
    setShowConfirmDialog(true);
  };

  const confirmDelete = () => {
    const { type, ids } = itemsToDelete;
    let cloneAllOffer = [...allOffers];

    if (type == types.single) {
      setDelLoader(true);

      deleteCoupon(ids)
        .then(({ data: { message } }) => {
          let checkIndx = cloneAllOffer.findIndex((ii) => ii?._id == ids);
          if (checkIndx > -1) {
            cloneAllOffer.splice(checkIndx, 1);
            setAllOffer(cloneAllOffer);
          }
          toastMessage(message, "success");
          //remove select id from state
          if (allId?.length) {
            let cloneAllIds = [...allId];
            let checkIdIndx = cloneAllIds.findIndex((ii) => ii == ids);
            if (checkIdIndx > -1) {
              cloneAllIds.splice(checkIdIndx, 1);
              setAllId(cloneAllIds);
            }
          }
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    } else {
      setDelLoader(true);
      deleteCoupon(allId?.toString())
        .then(({ data: { message } }) => {
          for (let index = 0; index < allId.length; index++) {
            const element = allId[index];
            let checkIndx = cloneAllOffer.findIndex((ii) => ii?._id == element);
            if (checkIndx > -1) {
              cloneAllOffer.splice(checkIndx, 1);
            }
          }
          setAllId([]);
          setAllOffer(cloneAllOffer);

          toastMessage(message, "success");
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    }

    // After deletion logic, reset state
    setItemsToDelete(null);
    setShowConfirmDialog(false);
  };

  const handleDeletes = (type, ids) => {
    let cloneAllOffer = [...allOffers];

    if (type == types.single) {
      setDelLoader(true);

      deleteCoupon(ids)
        .then(({ data: { message } }) => {
          let checkIndx = cloneAllOffer.findIndex((ii) => ii?._id == ids);
          if (checkIndx > -1) {
            cloneAllOffer.splice(checkIndx, 1);
            setAllOffer(cloneAllOffer);
          }
          toastMessage(message, "success");
          //remove select id from state
          if (allId?.length) {
            let cloneAllIds = [...allId];
            let checkIdIndx = cloneAllIds.findIndex((ii) => ii == ids);
            if (checkIdIndx > -1) {
              cloneAllIds.splice(checkIdIndx, 1);
              setAllId(cloneAllIds);
            }
          }
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    } else {
      setDelLoader(true);
      deleteCoupon(allId?.toString())
        .then(({ data: { message } }) => {
          for (let index = 0; index < allId.length; index++) {
            const element = allId[index];
            let checkIndx = cloneAllOffer.findIndex((ii) => ii?._id == element);
            if (checkIndx > -1) {
              cloneAllOffer.splice(checkIndx, 1);
            }
          }
          setAllId([]);
          setAllOffer(cloneAllOffer);

          toastMessage(message, "success");
        })
        .catch((err) => {
          toastMessage(err.response.data.message, "error");
        })
        .finally(() => setDelLoader(false));
    }
  };
  useDebounce(
    () => {
      getDonor();
    },
    [searchText],
    800
  );

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCoupon({});
  };

  return (
    <>
      <div className="flex spaceBetween itemCenter">
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "48px",
            letterSpacing: "-0.5px",
            color: "#11142D",
            marginBottom: "2%",
          }}
        >
          All Coupons
        </Typography>
        <div>
          {allId.length > 1 ? (
            <Button
              sx={{
                background: " rgba(255, 183, 190, 0.9);",
                color: "red",
                marginRight: "10px",
              }}
              onClick={() => handleDelete(types.multiple)}
            >
              <Delete style={{ color: "red" }} />
              Delete
            </Button>
          ) : (
            ""
          )}

          <Button
            variant="outlined"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Add />
            Coupon
          </Button>
        </div>
      </div>

      <TextField
        id="outlined-basic"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: "12px",
            border: "none",
            backgroundColor: "#F8F8F9",
            height: "56px",
          },
          autoComplete: "off",
        }}
        sx={{ width: "100%" }}
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        autoComplete="off"
      />
      {loader ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {allOffers?.length > 0 ? (
            <>
              <TableContainer
                className="tabledContainer"
                sx={{
                  marginTop: "2%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={allId.length ? true : false}
                          onChange={() => selectAll()}
                          {...label}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>Coupon Code</TableCell>
                      <TableCell>Reward</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Redirect Status</TableCell>
                      <TableCell>Redirect URL</TableCell>
                      <TableCell>Delete</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Redirect Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allOffers?.map((row, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              {...label}
                              checked={allId.includes(row?._id)}
                              size="small"
                              onChange={() => changCheckBox(row)}
                            />
                          </TableCell>
                          <TableCell>
                            <div>{row?.code}</div>
                          </TableCell>

                          <TableCell>
                            <div>{row?.reward}</div>
                          </TableCell>
                          <TableCell>{row?.status}</TableCell>
                          <TableCell>{row?.redirectStatus}</TableCell>
                          <TableCell>{row?.redirectUrl}</TableCell>
                          <TableCell>
                            <div style={{ display: "flex" }}>
                              <Button
                                variant="outlined"
                                sx={{
                                  minWidth: "45px",
                                  width: "35px",
                                  height: "40px",
                                  borderColor:
                                    "rgba(255, 183, 190, 0.9) !important",
                                  "&:hover": {
                                    background: " rgba(255, 183, 190, 0.9);",
                                  },
                                }}
                                onClick={() => {
                                  setDelIndx(index);
                                  handleDelete(types.single, row?._id);
                                }}
                              >
                                {delLoader && index == delIndx ? (
                                  <Loader colors={"red"} />
                                ) : (
                                  <Delete style={{ color: "red" }} />
                                )}
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            {row?.status === statuses.active ? (
                              <Button
                                sx={{
                                  background: " rgba(207 , 255, 204, 0.9);",
                                  marginLeft: "5%",
                                }}
                                onClick={() =>
                                  handleButtonClick(row._id, "inactive")
                                }
                              >
                                <LockOpenIcon style={{ color: "green" }} />
                              </Button>
                            ) : (
                              <Button
                                sx={{
                                  background: " rgba(255, 183, 190, 0.9);",
                                  marginLeft: "5%",
                                }}
                                onClick={() =>
                                  handleButtonClick(row._id, "active")
                                }
                              >
                                <LockPersonIcon style={{ color: "red" }} />
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>
                            {row?.redirectStatus === statuses.active ? (
                              <Button
                                sx={{
                                  background: " rgba(207 , 255, 204, 0.9);",
                                  marginLeft: "5%",
                                }}
                                onClick={() =>
                                  handleRedirectUpdate(row._id, "inactive")
                                }
                              >
                                <LockOpenIcon style={{ color: "green" }} />
                              </Button>
                            ) : (
                              <Button
                                sx={{
                                  background: " rgba(255, 183, 190, 0.9);",
                                  marginLeft: "5%",
                                }}
                                onClick={() =>
                                  handleRedirectUpdate(row._id, "active")
                                }
                              >
                                <LockPersonIcon style={{ color: "red" }} />
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Pagination
                lastPage={lastPage}
                active={active}
                setActive={setActive}
              /> */}
            </>
          ) : (
            <div className="flexCard" style={{ marginTop: "10%" }}>
              <img src={NoData} />
            </div>
          )}
        </>
      )}
      <ViewDetail
        setDetail={setOfferDetail}
        detail={offerDetail}
        open={openDetail}
        handleClose={handleCloseDetail}
      />

      {openModal ? (
        <CreateCoupon
          selectedCoupon={selectedCoupon}
          open={openModal}
          allUsers={allOffers}
          setUsers={setAllOffer}
          handleClose={handleCloseModal}
        />
      ) : (
        ""
      )}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
