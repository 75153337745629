import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";


const appointmentService = (query) => {
  // let obj = {
  //   page: 1,
  //   limit: 10,
  //   ,
  // };

  return HTTP_Request.post(
    BASE_URL + Endpoints.appointment.getAppintment , query
  );
};
const deleteAppointment = (appointids) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.appointment.deleteAppointment+"/"+appointids);
}
const getReasonAppoinment = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.appointment.allReason);
}
const createAppointment = (obj)=>{
  return HTTP_Request.post(BASE_URL + Endpoints.appointment.createAppointment,obj);
}
const updateAppointment = (appointId,obj)=>{
  debugger
  return HTTP_Request.patch(BASE_URL + Endpoints.appointment.updateAppointment+appointId,obj);
}
export { appointmentService,deleteAppointment,getReasonAppoinment,createAppointment,updateAppointment};
