import React from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { useState } from "react";
import CustomInput from "../../../shared/components/AutoComplete/CustomServerInput";
import { getUserService } from "../../../services/userService";
import useDebounce from "../../../hooks/debounce";
import { toastMessage } from "../../../shared/components/Toast";
import { AdRewardService } from "../../../services/adRewardService";
import { DelRewardService } from "../../../services/delRewardService";
import { useEffect } from "react";
function Rewards() {
  const [search, setSearch] = useState("");

  const [allUse, setAllUse] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addReward,setAddReward] = useState(0);
  const [RemoveReward,setRemoveReward] = useState(0);
  const [userOpenSuggestion, setUserOpenSuggestion] = useState(false);
  const [selectUser, setSelectUser] = useState(null);

  const getAllUser = () => {
    setLoader(true);
    let query = `?search=${search}`;
    getUserService(query)
      .then(({ data: data }) => {
        setAllUse(data?.users);

        // console.log(data,'<==datauser')
      })
      .finally(() => setLoader(false));
  };
  useDebounce(
    () => {
      getAllUser();
    },
    [search],
    800
  );
  const handleAddSubmit = ()=>{
    if(selectUser){
        if(addReward){
            let query=`?userId=${selectUser?._id}&rewardAmount=${addReward}`
            AdRewardService(query).then(({data:data})=>{
                toastMessage(data)
                let cloneSelectUser = {...selectUser};
                cloneSelectUser.rewardAmount = selectUser?.rewardAmount + Number(addReward)
                setSelectUser(cloneSelectUser)
                setAddReward(0)
            })
        }else{
            toastMessage("Reward should not be empty OR not be zero","error")
        }
    }else{
        toastMessage("Please select User","error")
    }
  }
  const handleRemoveSubmit = ()=>{
    if(selectUser){
        if(RemoveReward){
            let query=`?userId=${selectUser?._id}&rewardAmount=${RemoveReward}`
            DelRewardService(query).then(({data:data})=>{
                toastMessage(data)
                let cloneSelectUser = {...selectUser};
                cloneSelectUser.rewardAmount = selectUser?.rewardAmount - Number(RemoveReward)
                setSelectUser(cloneSelectUser);
                setRemoveReward(0)
            })
        }else{
            toastMessage("Reward should not be empty OR not be zero","error")
        }

    }else{
        toastMessage("Please select User","error")
    }
  }
  return (
    <div className="minHeight">
      <Box
        sx={{
          mt: 3,
          border: "1px solid #B9C5D275",
          borderRadius: "5px",

          mx: 1.5,
          pb: 3,
        }}
      >
        <Typography
          fontFamily={"inter"}
          fontSize={"12px"}
          fontWeight={500}
          sx={{ bgcolor: "#F8F8F8", py: 1.5, color: "#899CAF", pl: 1 }}
        >
          Reward Details
        </Typography>
        <Grid>
          <Typography
            fontFamily={"inter"}
            fontSize={"12px"}
            fontWeight={500}
            sx={{ mt: 2, mb: 2, ml: 2 }}
            id="starick"
          >
            Select User
          </Typography>
          <Box sx={{ mx: 2 }}>
            <CustomInput
              userArr={allUse}
              onSelect={(selectUser) => {
                setUserOpenSuggestion(false);
                setSelectUser(selectUser);
                setSearch(selectUser?.fullName  + "(" +selectUser?.phoneNumber+")");
              }}
              loader={loader}
              value={search}
              change={(e) => {
                setSelectUser(null);
                setSearch(e.target.value);
              }}
              setOpenSuggestion={setUserOpenSuggestion}
              openSuggestion={userOpenSuggestion}
            />
          </Box>

          {
          selectUser ? <Typography
          fontFamily={"inter"}
          fontSize={"12px"}
          fontWeight={500}
          sx={{ mt: 2,  ml: 2 }}
          id="starick"
        >
          Current Reward: { selectUser?.rewardAmount}
        </Typography>:null
          }
          
          <HandleInput title={"Add Reward"} value={addReward} handleChange={(value)=>setAddReward(value)} placeholder={"Add Reward"} handleSubmit={handleAddSubmit} />
          <HandleInput title={"Remove Reward"} value={RemoveReward} handleChange={(value)=>setRemoveReward(value)} placeholder={"Remove Reward"} handleSubmit={handleRemoveSubmit} />
          
        </Grid>
      </Box>
    </div>
  );
}
const HandleInput = ({title,value,handleChange,handleSubmit,placeholder}) => {
  return (
    <div>
      <Typography
            fontFamily={"inter"}
            fontSize={"12px"}
            fontWeight={500}
            sx={{ mt: 2,  ml: 2 }}
            id="starick"
          >
            {title}
          </Typography>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { mt: 1.5 },
          "& .MuiInputBase-root": {
            height: 50,
          },
          "& .MuiFormControl-root": {
            width: "100%",
          },

          display: "flex",
          padding: "0 17px",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          name="offer_tital"
          sx={{
            "& > :not(style)": {
              width: "100%",
              border: "1px solid lightgray",
            },
            "& .MuiInputBase-root": {
              borderRadius: 0,
              height:39
            },
          }}
          placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
        />
        <Button
          style={{
            background: "rgb(248, 61, 75)",
            color: "white",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            whiteSpace:"nowrap",
            padding:"0 19px",
            fontSize:"12px",
            "&:hover": {
              backgroundColor: "rgb(248, 61, 75)",
              color: "white",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          }}
            onClick={handleSubmit}
        >
          {title}
        </Button>
      </Box>
    </div>
  );
};

export default Rewards;
