import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
// import { useFormik } from 'formik';
// import * as yup from 'yup';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// import '../../../css/style.css'
import { Formik } from "formik";
import { createBlog } from "../../shared/validation/validation";
import { createBlogServ } from "../../services/blogService";
import { useNavigate,useLocation } from "react-router-dom";
import Loader from "../../shared/components/Loader";
import { toastMessage } from "../../shared/components/Toast";
import { useEffect } from "react";
function CreateBlog() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locationOfferDetail = location?.state?.blog;
  const initialValues = {
    title: "",
    url: "",
    type: "",
    oferImage: "",
    terms: "",
  };
  const handleCreateBlog = (obj) => {
    setLoading(true);
    createBlogServ(obj)
      .then(({ data }) => {
        toastMessage("Blog created Successfully!","success")
        navigate("/blog");
      })
      .catch((err) => {
        toastMessage(err?.response?.data?.message, 'error')
      })
      .finally(() => setLoading(false));
  };
  const numbers = Array.from({ length: 100 }, (_, i) => i + 1);
  useEffect(()=>{
    initialValues.title = locationOfferDetail?.title ? locationOfferDetail?.title:"" 
    initialValues.url = locationOfferDetail?.ctaValue ? locationOfferDetail?.ctaValue:"" 
    initialValues.type = locationOfferDetail?.ctaKind ? locationOfferDetail?.ctaKind:"" 
    initialValues.oferImage = locationOfferDetail?.imageURL ? locationOfferDetail?.imageURL:"" 
    initialValues.terms = locationOfferDetail?.description ? locationOfferDetail?.description:"" 
  },[locationOfferDetail])
  return (
    <>
      <Box className="husnain">
        <Box
          borderBottom={"1px solid #B9C5D275"}
          flexDirection={"column"}
          display={"flex"}
        >
          <Typography
            variant="h5 "
            fontFamily={"inter"}
            fontSize={"20px"}
            fontWeight={600}
            sx={{ mb: 1, mt: 3, ml: 3, color: "#2F496C" }}
          >
            Create Blog
          </Typography>

          <Typography
            variant="body6"
            fontFamily={"inter"}
            fontSize={"12px"}
            fontWeight={400}
            sx={{ mb: 2.5, ml: 3, color: "#899CAF" }}
          >
            Fill form to create an Offer to share with you audience.
          </Typography>
        </Box>
        <Formik
        enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, action) => {
            action.setSubmitting(true);
            // handleLogIn(values, action);
            console.log("values", values);
            let obj = {
                title: values?.title,
                imageURL: values?.oferImage,
                ctaKind: values?.type,
                ctaValue: values?.url,
                description: values?.terms,
              }
              if(location?.state?.isEdit){

              }else{

                handleCreateBlog(obj);
              }
          }}
          validationSchema={createBlog}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <Box display={"flex"} justifyContent={"center"}>
                <Box sx={{ mt: 5, pb: 5 }} border={"1px solid #B9C5D275"}>
                  <Box bgcolor={"#F8F8F8"} sx={{ py: 2 }}>
                    <Typography variant="body5" sx={{ ml: 2 }}>
                      Blog Details
                    </Typography>
                  </Box>
                  {/*-------------------- input ----------------------*/}
                  <Box sx={{ px: 3 }}>
                    <Grid container spacing={1}>
                      <Grid xs={6}>
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="body5"
                            fontFamily={"inter"}
                            fontSize={"12px"}
                            fontWeight={500}
                            color={"#899CAF"}
                            sx={{ ml: 0.7 }}
                            id="starick"
                          >
                            Blog Title
                          </Typography>
                        </Box>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": { mt: 1.5 },
                            "& .MuiInputBase-root": {
                              height: 50,
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            name="offer_tital"
                            sx={{
                              "& > :not(style)": { width: "100%" },
                            }}
                            placeholder="Enter Title"
                            value={values.title}
                            onChange={handleChange("title")}
                            error={touched.title && Boolean(errors.title)}
                            helperText={touched.title && errors.title}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={6}>
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="body5"
                            fontFamily={"inter"}
                            fontSize={"12px"}
                            fontWeight={500}
                            color={"#899CAF"}
                            sx={{ ml: 0.7 }}
                            id="starick"
                          >
                            Action URL
                          </Typography>
                        </Box>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": { mt: 1.5 },
                            "& .MuiInputBase-root": {
                              height: 50,
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            name="offer_tital"
                            sx={{
                              "& > :not(style)": { width: "100%" },
                            }}
                            placeholder="Action URL"
                            value={values.url}
                            onChange={handleChange("url")}
                            error={touched.url && Boolean(errors.url)}
                            helperText={touched.url && errors.url}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={6}>
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="body5"
                            fontFamily={"inter"}
                            fontSize={"12px"}
                            fontWeight={500}
                            color={"#899CAF"}
                            sx={{ ml: 0.7 }}
                            id="starick"
                          >
                            Click Type
                          </Typography>
                        </Box>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": { mt: 1.5 },
                            "& .MuiInputBase-root": {
                              height: 50,
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">
                              Select click action
                            </InputLabel>
                            <Select
                              className="myclick_dropdown fromcontrol_dropdown"
                              label="Select click action"
                              name="click_type"
                              value={values.type}
                              onChange={handleChange("type")}
                              //   renderValue={(selected) => {
                              //     if (selected.length < 2) {
                              //       return <em>Placeholder</em>;
                              //     }

                              //     return selected;
                              //   }}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {" "}
                              <MenuItem MenuItem>
                                --Choose and option--
                              </MenuItem>
                              <MenuItem value={"URL"}>Open URL</MenuItem>
                              <MenuItem value={"APPOINTMENT"}>
                                Book Appointment
                              </MenuItem>
                            </Select>
                            {touched.type && errors.type ? (
                              <span className="error">{errors.type}</span>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      
                      <Grid xs={12}>
                        <Typography
                          variant="body5"
                          fontFamily={"inter"}
                          fontSize={"12px"}
                          fontWeight={500}
                          color={"#899CAF"}
                          sx={{ ml: 0.7 }}
                          id="starick"
                        >
                          Offer Image
                        </Typography>
                        <TextField
                          style={{ width: "100%" }}
                          // label="Image*"
                          placeholder="Enter Offer Image URL"
                          value={values.oferImage}
                          onChange={handleChange("oferImage")}
                          error={touched.oferImage && Boolean(errors.oferImage)}
                          helperText={touched.oferImage && errors.oferImage}
                          sx={{
                            "& > :not(style)": { mt: 0 },
                            "& .MuiInputBase-root": {
                              width: "100% !important",
                            },
                          }}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          style={{ width: "100%" }}
                          placeholder="Define your offer by entering terms & conditions*"
                          name="creatoffer_discripton"
                          value={values.terms}
                          onChange={handleChange("terms")}
                          error={touched.terms && Boolean(errors.terms)}
                          helperText={touched.terms && errors.terms}
                          sx={{
                            "& > :not(style)": { mt: 1 },
                            "& .MuiInputBase-root": {
                              width: "100% !important",
                            },
                          }}
                          multiline
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                sx={{ mr: 4.5, mt: 4, pb: 5 }}
              >
                <Box>
                  <Button
                      onClick={()=>navigate(-1)}
                    sx={{
                      color: "#8B8D90",
                      bgcolor: "#EEEEEE",
                      px: 6,
                      py: 1.5,
                      mr: 2.5,
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <Button
                    disabled={loading}
                    sx={{
                      color: "#2E7FFD",
                      bgcolor: "#2E7FFD1F",
                      px: 6,
                      py: 1.5,
                    }}
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader colors={"#2278CF"} /> : location?.state?.isEdit ? "Update": "Create"}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default CreateBlog;
