import React, { useState } from 'react'
import Editor from '../shared/components/Editor'
import {
  getPrivacyService,
  updatePrivacyService,
} from '../services/policyService'

function Terms() {
  const [text, setText] = useState('')

  return (
    <>
      <Editor
        text={text}
        setText={setText}
        getAPI={getPrivacyService}
        updateAPI={updatePrivacyService}
        type="privacy"
      />
    </>
  )
}

export default Terms
