import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { Formik } from "formik";
import Loader from "../../../shared/components/Loader";
import { useNavigate } from "react-router-dom";
import { dynamicVS } from "../../../shared/validation/validation";
import { listDynamicURl,updateDynamicURl } from "../../../services/settingService";
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@mui/icons-material";
import { dynamicKey } from "../../../utils/constant";
import { toastMessage } from "../../../shared/components/Toast";
function Dynamic() {

  const [fcbLink, setFcbLink] = useState("");
  const [instLink, setinstLink] = useState("");
  const [linkedInLink, setlinkedInLink] = useState("");
  const [policyLink, setPolicyLink] = useState("");
  const [contact, setContact] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let cloneState = {};
    let obj = {};
    let query = "facebookUrl,instaUrl,PrivacyPolicy,linkedInUrl,ContactUs"
    listDynamicURl(query)
      .then(({ data }) => {
        let selectedKey = [
          {
            key: "linkedInUrl",
            statePropty: "linkedInLink",
          },
          {
            key: "instaUrl",
            statePropty: "instLink",
          },
          {
            key: "facebookUrl",
            statePropty: "fcbLink",
          },
          {
            key: "PrivacyPolicy",
            statePropty: "policyLink",
          },
          {
            key: "ContactUs",
            statePropty: "contact",
          },
        ];

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let findKey = selectedKey.find((ii) => ii.key === element?.key);
          if (findKey && Object.keys(findKey)?.length) {
            obj = {
              ...obj,
              [findKey.statePropty]: element.value,
            };
          }
        }
        obj = {
          ...cloneState,
          ...obj,
        };
        setFcbLink(obj?.fcbLink);
        setinstLink(obj?.instLink);
        setlinkedInLink(obj?.linkedInLink);
        setPolicyLink(obj?.policyLink);
        setContact(obj?.contact);
        // setInitialValues()
        console.log(obj);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  const handleUpdate = (key,values)=>{
    let obj = {
      value:values
    }

    updateDynamicURl(key,obj).then(({data})=>{
      toastMessage("Dynamic key value is successfully updated","success");
    }).catch((err)=>{
      toastMessage(err?.response?.data?.message,"error");
    })
    console.log({key,obj});
  }
  const handleSubmit = (key) => {
    console.log(key);
    if(dynamicKey.facebook == key){
      handleUpdate(key,fcbLink)
    }else if(dynamicKey.instagram == key){
      handleUpdate(key,instLink)
    }else if(dynamicKey.linkedIn == key){
      handleUpdate(key,linkedInLink)
    }else if(dynamicKey.contact == key){
      handleUpdate(key,contact)
    }else if(dynamicKey.privacy == key){
      handleUpdate(key,policyLink)
    }
  };
  return (
    <Box className="">
      <Box
        borderBottom={"1px solid #B9C5D275"}
        flexDirection={"column"}
        display={"flex"}
      >
        <Typography
          variant="h5 "
          fontFamily={"inter"}
          fontSize={"20px"}
          fontWeight={600}
          sx={{ mb: 1, mt: 3, ml: 3, color: "#2F496C" }}
        >
          Dynamic Links
        </Typography>

        <Typography
          variant="body6"
          fontFamily={"inter"}
          fontSize={"12px"}
          fontWeight={400}
          sx={{ mb: 2.5, ml: 3, color: "#899CAF" }}
        >
          One Place for your all links to control integrity Referral Program
        </Typography>
      </Box>
      
        <Box display={"flex"} justifyContent={"center"}>
          <Box sx={{ mt: 5, pb: 5 }} border={"1px solid #B9C5D275"}>
            <Box display={"flex"} bgcolor={"#F8F8F8"} sx={{ py: 2 }}>
              <span onClick={() => navigate(-1)}>
                <ArrowBack />
              </span>
              <Typography variant="body5" sx={{ ml: 2 }}>
                Links List
              </Typography>
            </Box>
            {/*-------------------- input ----------------------*/}
            <Box sx={{ px: 3 }}>
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body5"
                      fontFamily={"inter"}
                      fontSize={"12px"}
                      fontWeight={500}
                      color={"#899CAF"}
                      sx={{ ml: 0.7 }}
                      id="starick"
                    >
                      Facebook Link
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { mt: 1.5 },
                      "& .MuiInputBase-root": {
                        height: 50,
                      },
                      "& .MuiFormControl-root": {
                        width: "100%",
                      },
                      "& .MuiBox-root": {
                        display: "flex",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      name="offer_tital"
                      sx={{
                        "& > :not(style)": {
                          width: "100%",
                          border: "1px solid",
                        },
                      }}
                      placeholder="Facebook Link"
                      value={fcbLink}
                      onChange={(e) => setFcbLink(e.target.value)}
                    />
                    <Button
                      style={{
                        background: "rgb(248, 61, 75)",
                        color: "white",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        "&:hover": {
                          backgroundColor: "rgb(248, 61, 75)",
                          color: "white",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                      }}
                      onClick={() => handleSubmit(dynamicKey.facebook)}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body5"
                      fontFamily={"inter"}
                      fontSize={"12px"}
                      fontWeight={500}
                      color={"#899CAF"}
                      sx={{ ml: 0.7 }}
                      id="starick"
                    >
                      Instagram Link
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { mt: 1.5 },
                      "& .MuiInputBase-root": {
                        height: 50,
                      },
                      "& .MuiFormControl-root": {
                        width: "100%",
                      },
                      "& .MuiBox-root": {
                        display: "flex",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      name="offer_tital"
                      sx={{
                        "& > :not(style)": {
                          width: "100%",
                          border: "1px solid",
                        },
                      }}
                      placeholder="Instagram Link"
                      value={instLink}
                      onChange={(e) => setinstLink(e.target.value)}
                    />
                    <Button
                      style={{
                        background: "rgb(248, 61, 75)",
                        color: "white",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        "&:hover": {
                          backgroundColor: "rgb(248, 61, 75)",
                          color: "white",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                      }}
                      onClick={() => handleSubmit(dynamicKey.instagram)}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body5"
                      fontFamily={"inter"}
                      fontSize={"12px"}
                      fontWeight={500}
                      color={"#899CAF"}
                      sx={{ ml: 0.7 }}
                      id="starick"
                    >
                      LinkedIn Link
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { mt: 1.5 },
                      "& .MuiInputBase-root": {
                        height: 50,
                      },
                      "& .MuiFormControl-root": {
                        width: "100%",
                      },
                      "& .MuiBox-root": {
                        display: "flex",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      name="offer_tital"
                      sx={{
                        "& > :not(style)": {
                          width: "100%",
                          border: "1px solid",
                        },
                      }}
                      placeholder="Instagram Link"
                      value={linkedInLink}
                      onChange={(e) => setlinkedInLink(e.target.value)}
                    />
                    <Button
                      style={{
                        background: "rgb(248, 61, 75)",
                        color: "white",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        "&:hover": {
                          backgroundColor: "rgb(248, 61, 75)",
                          color: "white",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                      }}
                      onClick={() => handleSubmit(dynamicKey.linkedIn)}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body5"
                      fontFamily={"inter"}
                      fontSize={"12px"}
                      fontWeight={500}
                      color={"#899CAF"}
                      sx={{ ml: 0.7 }}
                      id="starick"
                    >
                      Privacy Policy Link
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { mt: 1.5 },
                      "& .MuiInputBase-root": {
                        height: 50,
                      },
                      "& .MuiFormControl-root": {
                        width: "100%",
                      },
                      "& .MuiBox-root": {
                        display: "flex",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      name="offer_tital"
                      sx={{
                        "& > :not(style)": {
                          width: "100%",
                          border: "1px solid",
                        },
                      }}
                      placeholder="Privacy Policy Link"
                      value={policyLink}
                      onChange={(e) => setPolicyLink(e.target.value)}
                    />
                    <Button
                      style={{
                        background: "rgb(248, 61, 75)",
                        color: "white",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        "&:hover": {
                          backgroundColor: "rgb(248, 61, 75)",
                          color: "white",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                      }}
                      onClick={() => handleSubmit(dynamicKey.privacy)}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body5"
                      fontFamily={"inter"}
                      fontSize={"12px"}
                      fontWeight={500}
                      color={"#899CAF"}
                      sx={{ ml: 0.7 }}
                      id="starick"
                    >
                      Contact US
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { mt: 1.5 },
                      "& .MuiInputBase-root": {
                        height: 50,
                      },
                      "& .MuiFormControl-root": {
                        width: "100%",
                      },
                      "& .MuiBox-root": {
                        display: "flex",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      name="offer_tital"
                      sx={{
                        "& > :not(style)": {
                          width: "100%",
                          border: "1px solid",
                        },
                      }}
                      placeholder="Contact US Link"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                    <Button
                      style={{
                        background: "rgb(248, 61, 75)",
                        color: "white",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        "&:hover": {
                          backgroundColor: "rgb(248, 61, 75)",
                          color: "white",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                      }}
                      onClick={() => handleSubmit(dynamicKey.contact)}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
    </Box>
  );
}

export default Dynamic;
