import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";



const getPrivacyService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getPrivacyPolicy);
}

const getTermsService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getTerms);
}

const updatePrivacyService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updatePrivacyPolicy, formBody);
}

const updateTermsService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateTerms, formBody);
}



export { getPrivacyService, getTermsService, updatePrivacyService, updateTermsService };
