import { Subscript } from "@mui/icons-material";
import Dashboard from "../pages/Dashboard";
import Privacy from "../pages/Privacy";
import Users from "../pages/user/Users";
import Login from "./../pages/Login";
import Terms from "./../pages/Terms";
import Shop from "../pages/shop/index";
import UpdateShop from "../pages/updateShop";
import Offer from "../pages/offer";
import CreateOffer from "../pages/createOffer";
import BLog from "../pages/blog/blog";
import Coupon from "../pages/coupon/coupon";
import CreateBLog from "../pages/blog/createBlog";
// import CreateCoupon from '../pages/coupon/createCoupon'
import BlockedList from "../pages/BlockedList";
import DynamicLink from "../pages/setting/dynamicLink/dynamic";
import Onboarding from "../pages/setting/onboarding/onboarding";
import Reward from "../pages/setting/reward/reward";
import SystemLogo from "../pages/setting/systemLogo/systemLogo";
import MediaUpload from "../pages/setting/media";
import Appointment from "../pages/appointment/Appointment";
import CreateAppoint from "../pages/createAppoint";
// import Areward from '../pages/setting/Areward/Areward'
import Rewards from "../pages/setting/breward/Rewards";
// import Areward from '../pages/setting/aRewards/aRewards'

const privateRoutes = [
  {
    component: Dashboard,
    path: "/",
    title: "Dashboard",
    name: "Dashboard",
  },
  {
    component: Users,
    path: "/users",
    title: "Users",
  },
  {
    component: Shop,
    path: "/shop",
    title: "Shops",
  },
  {
    component: UpdateShop,
    path: "/updateShop",
    title: "Update Shops",
  },
  {
    component: BLog,
    path: "/blog",
    title: "Blog",
  },
  {
    component: CreateBLog,
    path: "/createBlog",
    title: "Blog",
  },
  {
    component: Offer,
    path: "/offer",
    title: "Offers",
  },
  {
    component: CreateOffer,
    path: "/createOffer",
    title: "Create Offers",
  },
  {
    component: Privacy,
    path: "/privacy",
    title: "Privacy Policy",
  },
  {
    component: DynamicLink,
    path: "/dynamiclinks",
    title: "Dynamic Links",
  },
  {
    component: Onboarding,
    path: "/onboarding",
    title: "Onboarding",
  },
  {
    component: Reward,
    path: "/reward",
    title: "Rewards",
  },
  {
    component: Rewards,
    path: "/firsReward",
    title: "A Reward",
  },
  {
    component: SystemLogo,
    path: "/systemLogo",
    title: "System Logo",
  },
  {
    component: MediaUpload,
    path: "/media",
    title: "Media Upload",
  },
  {
    component: BlockedList,
    path: "/blockedUser",
    title: "Blocked Users",
  },
  {
    component: Appointment,
    path: "/appointments",
    title: "Appointment",
  },
  {
    component: CreateAppoint,
    path: "/createAppoint",
    title: "Create Appointment",
  },
  {
    component: Coupon,
    path: "/coupon",
    title: "Coupons",
  },
];

const publicRoutes = [
  {
    component: Login,
    path: "/",
    title: "Login",
  },
];

export { publicRoutes, privateRoutes };
