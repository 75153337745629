import * as React from "react";
import { useEffect } from "react";

import { useState } from "react";
import { List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
function CustomInput({
  value,
  change,
  userArr,
  onSelect,
  setOpenSuggestion,
  openSuggestion,
  loader,
  disable,
}) {
  function handleClick(e) {
    const elem = document.getElementById("wrapper");
    if (elem) {
      if (!elem?.contains(e.target)) {
        closeSuggestion();
      }
    }
  }
  useEffect(() => {
    return () => {
      document.body.removeEventListener(
        "click",
        (event) => {
          handleClick(event);
        },
        true
      );
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.body.addEventListener(
      "click",
      (event) => {
        handleClick(event);
      },
      true
    );
    // eslint-disable-next-lines
  }, []);
  const closeSuggestion = () => setOpenSuggestion(false);
// console.log({searchByfieldName,search});
  return (
    <>
      <div className="wrapper" id="wrapper">
        <input
          type={"text"}
          disabled={disable}
          className="suggestionInput"
          value={value}
          onClick={() => setOpenSuggestion(true)}
          onChange={change}
        />
        {openSuggestion ? (
          <div className="suggestion">
            <List>
              {loader ? (
                <div className="flexcenter">
                  <CircularProgress size={14} />
                </div>
              ) : userArr?.length ? (
                userArr?.map((item, inx) => {
                  return item?.fullName || item?.email ? (
                    <RenderList
                      text={
                        item?.fullName
                          ? item?.fullName
                          : item?.email.substr(0, 3)
                      }
                      key={`list-${inx}`}
                      click={() => {
                        onSelect(item);
                      }}
                    />
                  ) : (
                    ""
                  );
                })
              ) : (
                "Not Found"
              )}
            </List>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
const RenderList = ({ text, click }) => {
  return (
    <>
      <ListItemButton onClick={click}>
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </>
  );
};

export default React.memo(CustomInput);
