import * as React from "react";
import { useEffect } from "react";

import { useState } from "react";
import { List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
function SuggestionAutoComplete({
  search,
  setSearch,
  userArr,
  onSelect,
  setOpenSuggestion,
  vehicleSuggestion,
  loader,
  disable,
  searchByfieldName,
}) {
  function handleClick(e) {
    const elem = document.getElementById(searchByfieldName ? searchByfieldName: "wrappers");
    if (elem) {
      if (!elem?.contains(e.target)) {
        closeSuggestion();
      }
    }
  }
  useEffect(() => {
    return () => {
      document.body.removeEventListener(
        "click",
        (event) => {
          handleClick(event);
        },
        true
      );
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.body.addEventListener(
      "click",
      (event) => {
        handleClick(event);
      },
      true
    );
    // eslint-disable-next-lines
  }, []);
  const closeSuggestion = () => setOpenSuggestion(false);

  return (
    <>
      <div className="wrapper" id={searchByfieldName ? searchByfieldName: "wrappers"}>
        <input
          type={"text"}
          disabled={disable}
          className="suggestionInput"
          value={search}
          onClick={() => setOpenSuggestion(true)}
          onChange={(e) => setSearch(e.target.value)}
        />
        {vehicleSuggestion ? (
          <div className="suggestion">
            <List>
              {userArr?.length
                ? userArr
                    ?.filter((ii) =>
                      ii[searchByfieldName]
                        .toLocaleLowerCase()
                        .includes(search?.toLocaleLowerCase())
                    )
                    ?.map((item, inx) => {
                      return (
                        <RenderList
                          text={item[searchByfieldName]}
                          key={`list-${inx}`}
                          click={() => {
                            
                            onSelect(item);
                          }}
                        />
                      );
                    })
                : "Not Found"}
            </List>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
const RenderList = ({ text, click }) => {
  return (
    <>
      <ListItemButton onClick={click}>
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </>
  );
};

export default React.memo(SuggestionAutoComplete);
