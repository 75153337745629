import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";

const listDynamicURl = (query) => {
  
  return HTTP_Request.get(BASE_URL + Endpoints.setting.dynamicWithComma+query);
}
const updateDynamicURl = (key,obj) => {
  
  return HTTP_Request.patch(BASE_URL + Endpoints.setting.updateDynamicKey+key,obj);
}



export { listDynamicURl,updateDynamicURl };
