import React, { useState, useEffect } from 'react'
import '../styles/table.css'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Typography,
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ViewDetail from './../shared/Modals/ViewDetails'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import {
  getUserService,
  blockService,
  unblockService,
} from '../services/userService'
import Loader from '../shared/components/Loader'
import { Sample } from '../assets/images'
import { toastMessage } from '../shared/components/Toast'
import Pagination from '../shared/components/Pagination'
import { NoData } from '../assets/images/index'
import { useLogoutHelper } from '../utils/helper'

export default function BlockedUsers() {
  const [openDetail, setOpenDetail] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [loader, setLoader] = useState(false)
  const [users, setUsers] = useState([])
  const [lastPage, setLastPage] = useState(1)
  const [active, setActive] = useState(1)
  const [selectedUser, setSelectedUser] = useState({})
  const { handleLogout } = useLogoutHelper()

  const handleClickOpenDetail = (user) => {
    setOpenDetail(true)
    setSelectedUser(user)
  }

  const handleCloseDetail = () => {
    setOpenDetail(false)
  }

  const getUsers = () => {
    let obj = {
      page: active,
      pagination: 10,
      search_text: searchText,
      filter: 'blocked',
    }
    getUserService(obj)
      .then(({ data } = data) => {
        setLoader(false)
        setLastPage(data?.data?.last_page)
        setUsers(data?.data?.userList)
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.message, 'error')
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    // getUsers()
  }, [searchText, active])

  const handleBlock = (user, type) => {
    if (type == 'block') {
      blockService({ id: user?.id })
        .then(({ data } = data) => {
          toastMessage(data?.message)
          let temp = [...users]
          let Index = users.findIndex((i) => i.id == user.id)
          if (Index > -1) {
            temp[Index]['is_blocked'] = true
          }
          setUsers(temp)
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message)
        })
    } else {
      unblockService({ id: user?.id })
        .then(({ data } = data) => {
          toastMessage(data?.message)
          let temp = [...users]
          let Index = users.findIndex((i) => i.id == user.id)
          if (Index > -1) {
            temp[Index]['is_blocked'] = false
            temp.splice(Index, 1)
          }
          setUsers(temp)
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, 'error')
        })
    }
  }

  return (
    <>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 32,
          lineHeight: '48px',
          letterSpacing: '-0.5px',
          color: '#11142D',
          marginBottom: '2%',
        }}
      >
        All Blocked Users
      </Typography>
      <TextField
        id="outlined-basic"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: '12px',
            border: 'none',
            backgroundColor: '#F8F8F9',
            height: '56px',
          },
          autoComplete: 'off',
        }}
        sx={{ width: '100%' }}
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        autoComplete="off"
      />
      {loader ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {users.length > 0 ? (
            <>
              <TableContainer
                className="tabledContainer"
                sx={{
                  marginTop: '2%',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Platform</TableCell>
                      <TableCell>Detail</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {index == 9 && active != 1
                            ? active.toString() + 0
                            : active != 1
                            ? (active - 1).toString() + (index + 1)
                            : index + 1}
                        </TableCell>
                        <TableCell>
                          <div className="tableNameDiv">
                            <img
                              src={
                                row.social_network && row.avatar_thumbnail
                                  ? row.avatar_thumbnail
                                  : row.avatar_thumbnail
                                  ? row.base_url + row.avatar_thumbnail
                                  : Sample
                              }
                              height="50"
                              width="50"
                              style={{ borderRadius: '50%' }}
                            />
                            <div style={{ marginLeft: '10%' }}>
                              <div className="taleName">
                                {row.first_name + ' ' + row.last_name}
                              </div>
                              <div className="tableUsername">
                                {'@' + row.user_name}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {row.email
                            ? 'Email: ' + row?.email
                            : row.social_network}
                        </TableCell>
                        <TableCell>
                          <Button
                            sx={{
                              background: ' rgba(108, 93, 211, 0.1);',
                            }}
                            onClick={() => handleClickOpenDetail(row)}
                          >
                            <RemoveRedEyeIcon style={{ color: '#6C5DD3' }} />
                          </Button>
                        </TableCell>
                        <TableCell>
                          {!row.is_blocked ? (
                            <Button
                              sx={{
                                background: ' rgba(255, 183, 190, 0.9);',
                                marginLeft: '5%',
                              }}
                              onClick={() => handleBlock(row, 'block')}
                            >
                              <LockPersonIcon style={{ color: 'red' }} />
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                background: ' rgba(207 , 255, 204, 0.9);',
                                marginLeft: '5%',
                              }}
                              onClick={() => handleBlock(row, 'unblock')}
                            >
                              <LockOpenIcon style={{ color: 'green' }} />
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                lastPage={lastPage}
                active={active}
                setActive={setActive}
              />
            </>
          ) : (
            <div className="flexCard" style={{ marginTop: '10%' }}>
              <img src={NoData} />
            </div>
          )}
        </>
      )}
      <ViewDetail
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        open={openDetail}
        handleClickOpen={handleClickOpenDetail}
        handleClose={handleCloseDetail}
      />
    </>
  )
}
