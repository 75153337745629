import './App.css'
import AllRoutes from './routes/routes'
import { ToastContainer } from 'react-toastify'
import './styles/main.css'
import './styles/responsive.css'

function App() {
  return (
    <div>
      <AllRoutes />
      <ToastContainer />
    </div>
  )
}

export default App
