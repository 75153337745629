import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";

const listAllBlog = (query) => {
  
  return HTTP_Request.get(BASE_URL + Endpoints.blog.list+query);
}
const createBlogServ = (obj) => {
  
  return HTTP_Request.post(BASE_URL + Endpoints.blog.create,obj);
}
const deleteBlog = (offerids) => {
  
  return HTTP_Request.delete(BASE_URL + Endpoints.blog.deleteBlog+"/"+offerids);
}


export { listAllBlog,createBlogServ,deleteBlog };
