import React, { useState, useEffect } from "react";
import "../../styles/table.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { listAllShop } from "../../services/shopService";
import Loader from "../../shared/components/Loader";
import { Sample } from "../../assets/images";
import Pagination from "../../shared/components/Pagination";
import { NoData } from "../../assets/images/index";
import { toastMessage } from "../../shared/components/Toast";
import { useLogoutHelper } from "../../utils/helper";
import { ModeEdit, Visibility, Add } from "@mui/icons-material";
import ViewDetail from "./../../shared/Modals/detailShop";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/debounce";
function Index() {
  let types = {
    single: "single",
    multiple: "multiple",
  };
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(true);
  const [allOffers, setAllOffer] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [active, setActive] = useState(1);

  const [shopDetails, setShopDetails] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [delLoader, setDelLoader] = useState(false);
  const [delIndx, setDelIndx] = useState(-1);
  const { handleLogout } = useLogoutHelper();

  const getDonor = () => {
    // let query = `${searchText ? "?search=" + searchText : ""} `;
    let query = "";
    listAllShop(query)
      .then(({ data } = data) => {
        setLoader(false);
        console.log("data", data);
        if (data) {
          debugger;
          data.forEach((item, index, array) => {
            array[index] = {
              ...item,
              nickname: item?.nickname
                ? item.nickname.toLowerCase()
                : "no shop name",
            };
          });
        }

        // setLastPage(data?.total);
        setAllOffer(data);
      })
      .catch((err) => {
        toastMessage(err?.message, "error");
        setLoader(false);
        if (err?.response?.status == 401) {
          handleLogout();
        }
      });
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  // useEffect(() => {
  //   getDonor();
  // }, [active]);

  const handleClickOpenDetail = (user) => {
    setOpenDetail(true);
    setShopDetails(user);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  useDebounce(
    () => {
      setLoader(true);
      getDonor();
    },
    [],
    800
  );

  let gettt = allOffers?.filter((item) =>
    item?.nickname?.includes(searchText.toLocaleLowerCase())
  );
  return (
    <>
      <div className="flex spaceBetween itemCenter">
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "48px",
            letterSpacing: "-0.5px",
            color: "#11142D",
            marginBottom: "2%",
          }}
        >
          All Shops
        </Typography>
      </div>

      <TextField
        id="outlined-basic"
        variant="outlined"
        InputProps={{
          style: {
            borderRadius: "12px",
            border: "none",
            backgroundColor: "#F8F8F9",
            height: "56px",
          },
          autoComplete: "off",
        }}
        sx={{ width: "100%" }}
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        autoComplete="off"
      />
      {loader ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {gettt?.length > 0 ? (
            <>
              <TableContainer
                className="tabledContainer"
                sx={{
                  marginTop: "2%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Shops</TableCell>
                      <TableCell>Shop Address</TableCell>
                      <TableCell>G-Map Link</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gettt?.map((row, index) => (
                      <>
                        <TableRow key={index}>
                          <TableCell>
                            <div className="tableNameDiv">
                              <img
                                src={row?.image ? row?.image : Sample}
                                height="50"
                                width="50"
                                style={{ borderRadius: "50%" }}
                              />
                              <div style={{ marginLeft: "10%" }}>
                                <div className="taleName">{row?.nickname}</div>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell>
                            <div>{row?.address?.fullAddress}</div>
                          </TableCell>
                          <TableCell>
                            <span
                              className="gLink"
                              onClick={() => window.open(row?.googleMapsLink)}
                            >
                              G-Map Link
                            </span>
                          </TableCell>
                          <TableCell>{row?.phone}</TableCell>
                          <TableCell>
                            <div style={{ display: "flex" }}>
                              <Button
                                variant="outlined"
                                sx={{
                                  minWidth: "43px",
                                  borderColor: "#c8bcbc !important",
                                  "&:hover": {
                                    background: "#FEE8CE",
                                  },
                                }}
                                onClick={() => handleClickOpenDetail(row)}
                              >
                                <Visibility style={{ color: "#FD8A25" }} />
                              </Button>
                              <Button
                                variant="outlined"
                                sx={{
                                  marginLeft: "5%",
                                  marginRight: "5%",
                                  minWidth: "43px",
                                }}
                                onClick={() => {
                                  navigate("/updateShop", {
                                    state: {
                                      shop: row,
                                      isEdit: true,
                                    },
                                  });
                                }}
                              >
                                <ModeEdit />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Pagination
                lastPage={lastPage}
                active={active}
                setActive={setActive}
              /> */}
            </>
          ) : (
            <div className="flexCard" style={{ marginTop: "10%" }}>
              <img src={NoData} />
            </div>
          )}
        </>
      )}
      <ViewDetail
        setDetail={setShopDetails}
        detail={shopDetails}
        open={openDetail}
        handleClose={handleCloseDetail}
      />
      {/* <ChangePassword /> */}
    </>
  );
}

export default Index;
